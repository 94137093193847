import { SET_CLIENTS, SET_CLIENTS_LOADING, SET_CLIENTS_DETAIL } from "./ClientsConstants";

const initialState = {
   loading:true,
   clients:null,
   clientDetail:null
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_CLIENTS:
        return {
            ...state,
            clients: action.payload
        }  
    case SET_CLIENTS_LOADING:
        return {
            ...state,
            loading: action.payload
        } 
    case SET_CLIENTS_DETAIL:
        return {
            ...state,
            clientDetail: action.payload
        } 
    default:
    return state;
} 
};