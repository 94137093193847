import React, { useEffect, useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux"
import { useGetSuccessStories } from '../../hooks/useGetSuccessStories';
import { setClientDetail } from '../../redux/clients/ClientsActions';
import { CarrouselContainer, ChevronIcon, ClientCard, SuccessContainer, TitleContainer } from './styles';
import { Spinner } from '../../styles/global';

export default function Success(){
    const {translate:{success}} = useSelector(({language})=>language)
    const {loading, clients} = useGetSuccessStories()
    const dispatch = useDispatch()
    const history = useHistory()
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    const [tamaño, setTamaño]= useState({ windowWidth: window.innerWidth })
    const [nCards, setNCards] = useState(2)
    const handleResize = (e) => {
      setTamaño({ windowWidth: window.innerWidth });
     };
  
    useEffect(()=>{
      window.addEventListener("resize", handleResize); 
      return ()=> window.removeEventListener("resize", handleResize);
    },[])
  
    useEffect(()=>{
      if(tamaño.windowWidth < 510) setNCards(1)
      else setNCards(2)
    },[tamaño])

    function handleOnClick(client){
      dispatch(setClientDetail(client))
      history.push("/casos/"+client.name)
    }
  
    return(

        <SuccessContainer>
        {  !(history.location.pathname === "/casos") &&
        (
        <TitleContainer>
        <h1>{success.title}</h1>
        <p>{success.subtitle}</p>
        </TitleContainer>
        )
        }
        {
        loading ?
        <Spinner></Spinner>
        :
        <CarrouselContainer>
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={nCards}
                gutter={20}
                leftChevron={<ChevronIcon className={"fas fa-chevron-left"}></ChevronIcon>}
                rightChevron={<ChevronIcon className={"fas fa-chevron-right"}></ChevronIcon>}
                outsideChevron
                chevronWidth={40}
            >
               { clients.map((e)=>{
                   return(
                        <ClientCard onClick={()=>handleOnClick(e)} >
                            <img alt="energia store" src={e.image.url} />
                        </ClientCard>
                        )
                     }
                   )
                }
                
            </ItemsCarousel>
        </CarrouselContainer>}
    </SuccessContainer>
  
    )
}