import styled from "styled-components";
import { COLOR_SUCCESS } from "../../styles/global";

export const FloatsContainer = styled.div`
    position: fixed;
    left: 1.5rem;
    z-index: 3000;
    bottom: 2rem;
`

export const ButtonContainer = styled.div`
    cursor: pointer;
    i{
        display: block;
        font-size: 3rem;
        text-align: center;
        color: white;
        width: 2rem;
        border-radius: 50%;
        padding: .5rem 1rem;
        box-sizing: content-box;
        margin: auto;
        background-color:${COLOR_SUCCESS};
        box-shadow: -1px 1px 6px #000000c4;

        ::before{
            filter: drop-shadow(2px 4px 6px black);
        }
    }
    label{
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 4000;
        font-weight: bolder;
        font-variant: unicase;
        cursor: pointer;

    }
    animation-name: fade-in;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
    @keyframes fade-in {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.1);
        }
    }
`