import React from 'react'
import styles from './About.module.scss'
import {  useSelector } from "react-redux"

const About  = () => {
    
    const {translate:{about}} = useSelector(({language})=>language)

    return(
        
        <div className={styles.container}>
            <h1 >{about.title}</h1>
            <div>
                <p>{about.p_1}</p>
               
                <h3>{about.sub_1}</h3>
                <p>{about.p_2}</p>                
                <h3>{about.sub_2}</h3>
                <p>{about.p_3}</p>                
                
                <h3>{about.sub_3}</h3>
                <p>{about.p_4}</p>                
    
            </div>
        </div>
    )

}


export default About;


