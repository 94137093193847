import Banner from "../../components/Banner/Banner";
import Clients from "../../components/Clients/Clients";
import TitleSection from "../../components/TitleSection/TitleSection";
import PFactorFor from "../../components/PFactorFor/PFactorForm";
import {  useSelector } from "react-redux"

export default function Optimizacion(){
    const {translate:{pfp}} = useSelector(({language})=>language)

    return(
        <>
        <Banner name={"eb"}/>
        <TitleSection name={pfp.title} />
        <PFactorFor />
        <div class="container">
            <Clients/>
        </div>
        </>
    )
}