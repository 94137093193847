import { translate_en } from "../../utils/translate_en";
import { translate_es } from "../../utils/translate_es";
import { SET_ACTIVE_LANGUAGE } from "./LanguageConstants";

export const setActiveLanguage = (activeLanguage) => async (dispatch) => {
    try {
        const translate = activeLanguage === "es"? translate_es: translate_en;
        localStorage.setItem("ESlang",activeLanguage)
        dispatch({
            type: SET_ACTIVE_LANGUAGE,
            payload: {
                activeLanguage,
                translate
            }
        });

    } catch (error) {
        console.log("error en SET_ACTIVE_LANGUAGE", error)
    }
}