import { useHistory } from 'react-router'
import styles from "./index.module.scss"
import {  useDispatch, useSelector } from "react-redux"
import { setActiveEmergency } from '../../redux/emergency/EmergencyActions'

export default function Switch({page}){
	const history= useHistory()
	const dispatch = useDispatch()
	const {translate:{switchw}} = useSelector(({language})=>language)

	function handleSetEmergency(){
		dispatch(setActiveEmergency(true))
	}

	return(
		<section id={styles.switch}>
			<div>
				<div>	
					<button 
					className={page==="ahorro"?styles.active:null} 
					aria-current="page"
					onClick={handleSetEmergency}
					>
						<i class="fas fa-exclamation-triangle text-white"></i>{switchw.emergency}
					</button>
				</div>
				<div>
					<button 
					className={page==="electricbill"?styles.active:null} 
					aria-current="page" 
					onClick={()=>history.push("/electricbill")}>
						<i className="far fa-lightbulb mr-2 text-white"></i>{switchw.bills}
					</button>
				</div>
				<div>
					<button 
					className={page==="distribucion"?styles.active:null} 
					aria-current="page" 
					onClick={()=>window.open(process.env.REACT_APP_ADMIN)}
					>
						<i class="far fa-building"></i>{switchw.access}
					</button>
				</div>
			</div>
		</section>
	)
}