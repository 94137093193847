/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import styles from './index.module.scss'
import motores from '../../images/motores.png'
import { useHistory } from 'react-router'

export default function ElectricMotorInfo () {
    const history = useHistory()
    return(
    <div id={styles.infoContainer}>
        <div>
            <h2 >Optimización de</h2>
            <h1 >Motores Eléctricos</h1>
            <div >
                <p>
                El tema de la eficiencia energética toma importancia ante el aumento en el uso de la energía eléctrica en países donde el crecimiento energético se contrapone con el crecimiento industrial. Ante esa visión, la generación de energía está limitada y aprovechando la existente simplemente debemos hacer más eficientes los usos. Ahora bien, existen variados equipamientos eléctricos asociados con bajo consumo, pero el caso más relevante sin duda que son los motores eléctricos.
                </p>
                <p>
                En la industria cerca del 60% de la energía eléctrica se "consume" en motores eléctricos. De éstos, el 70% corresponden a motores trifásicos de inducción tipo jaula de ardilla.
                </p>
                <p>
                La eficiencia de este tipo de motor está dada en la razón entre la potencia que se obtiene nominalmente en el eje del motor (potencia mecánica) y la potencia absorbida por el motor (potencia eléctrica). De esta forma, la diferencia entre ambas potencias corresponderá a las pérdidas eléctricas y mecánicas asociadas a la conversión de energía que se produce en cada motor eléctrico.
                </p>
                <p>
                La potencia en el eje está dada nominalmente por el fabricante (potencia nominal del motor), al igual que el factor de potencia, tensión, corriente absorbida y en muchos casos la eficiencia misma. Si no es conocida, la eficiencia se puede determinar a través de los anteriores datos del fabricante.
                </p>
                <p>
                Los motores eléctricos son los usuarios de mayor consumo de energía eléctrica en plantas industriales. Aproximadamente entre el 60 y 70 % del consumo de energía eléctrica de una industria corresponde a equipos electromotrices tales como ventiladores, bombas, compresores, bandas transportadoras, etc.
                </p>
                <p>
                Es evidente el gran impacto de los motores eléctricos en el consumo de energía en el sector industrial, por tanto, resalta la importancia de identificar y evaluar oportunidades de ahorro de energía en ellos. Sin embargo, es necesario determinar con precisión el estado energético actual de los mismos (factor de carga, eficiencia, factor de potencia, antigüedad, etc.) y conocer sistemas alternativos como son motores de alta eficiencia los cuales son de alto valor, con lo que su ROI es superior 4 a 5 años y  la mejor alternativa es instalar equipos KVAR a motores convencionales lo que permite alcanzar ROI menores a los 24 meses en casi todas las ocasiones.
                </p>     
                <button onClick={()=>history.push("/optimizacion")} type="button" className={"btn btn-lg "+ styles.boton}>SOLICITAR PRESUPUESTO <i className="fas fa-angle-right"></i></button>                        
            </div>
        </div>
        <div>
            <img alt="energia store" src={motores} ></img>
        </div>
    </div>
)
}


