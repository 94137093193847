import styled from "styled-components";
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_SUCCESS } from "../../styles/global";

export const FormContainer = styled.form`

    display: flex;
    flex-direction: column;
    max-width: 25rem;
    margin: auto;
    transition:filter .5s; 
    filter: ${({isDisabled})=>isDisabled?"brightness(0.5)":"brightness(1)"};
    input{
        margin:.5rem 0;
        border: 1px solid darkgrey;
        padding: 0.2rem .5rem;
    }
    button{
        background-color:${COLOR_SECONDARY};
        border: none;
        outline: none;
        padding: .5rem 0;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
    }
    div{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 1rem;
        label{
            display: block;
            flex-grow: 1;
            width: 100%;
            font-weight: 600;
            margin-bottom: .5rem;
        }
        a{
            cursor: pointer;
            color:#000;

            i{
                font-size: 3rem;
                margin: 0 1rem;
            }
            &:nth-child(2){
                :hover{
                    color:${COLOR_PRIMARY}
                }
            }
            &:nth-child(3){
                :hover{
                    color:${COLOR_SUCCESS}
                }
            }
        }
        
    }
`