import styled from "styled-components";
import { EXTRA_DESKTOP, COLOR_SUCCESS, COLOR_LIGHT, COLOR_PRIMARY } from "../../styles/global";

export const FormContainer = styled.form`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: auto;
    max-width: 10rem;
    input{
        outline: none;
        border: none;
        background: ${COLOR_LIGHT};
        text-align: center;
        border-radius: 6px 0 0 6px;
        ::-webkit-inner-spin-button {
            opacity: 1
        }
        font-weight: bold;
    }
    button {
        outline: none;
        display: flex;
        align-items: baseline;
        :first-of-type{
            border: 1px solid ${COLOR_LIGHT};
            padding: .5rem;
            justify-content: space-evenly;
            width: fit-content;
            border-radius: 0 6px 6px 0;
            background: #fff;
            position: relative;
            ::before{
                transition: opacity .2s;
                content:"${({content})=>content}";
                position: absolute;
                border-radius: 9999px;
                height: 1.5rem;
                top: -29px;
                border: 2px solid grey;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                padding: 0 .5rem;
                opacity: 0;
                right: -18px;
                font-weight: 500;
                font-variant-caps: all-small-caps;
                color: grey;
                background: #fff;
            }
            ::after{
                transition: opacity .2s;
                content: "";
                position: absolute;
                top: -6px;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid grey;
                opacity:0;

            }
            :hover{
                ::before{
                    opacity:1;
                }
                ::after{
                    opacity:1;
                }
            }
        }
    }
    @media only screen and (min-width:${EXTRA_DESKTOP}){
        
    }
    
`

export const PdfButton = styled.button`

    background-color:${COLOR_SUCCESS};
    border: 1px solid ${COLOR_SUCCESS};
    align-items: center;
    font-size: 1rem;
    color: #fff;
    padding: .5rem;
    border-radius: 6px;
    justify-content: center;
`

export const WpButton = styled.button`

    background-color:#fff;
    border: 1px solid ${COLOR_LIGHT};
    align-items: center;
    font-size: 1rem;
    color: #fff;
    padding: .5rem;
    border-radius: 6px;
    justify-content: center;
    i{
        color: ${COLOR_PRIMARY};
    }
`