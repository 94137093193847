import { useDispatch, useSelector } from "react-redux";
import { setEmergencyReset, setEmergencySubmit } from "../../redux/emergency/EmergencyActions";
import { FormContainer } from "./styles";

export function EmergencyForm(){

    const dispatch = useDispatch()

    const {translate:{emergency}} = useSelector(({language})=>language)

    const {isSubmitted, emergencyLoading} =useSelector(({emergency})=>emergency)

    async function handleEmergencySubmit(e){
        e.preventDefault();
        let data = {}
        for (let i = 0; i < e.target.elements.length; i++) {         
            if(e.target.elements[i].tagName === "INPUT"){
                data={...data, [e.target.elements[i].name]:e.target.elements[i].value}
            }
        }
        dispatch(setEmergencySubmit(data))
        setTimeout(function(){
            dispatch(setEmergencyReset())
        },[5000])
    }


    return(
        isSubmitted
        ?
        isSubmitted.success
        ?
        <h1>{emergency.successTitle}</h1>
        :
        <h1>{emergency.errorTitle}</h1>
        :
        <FormContainer onSubmit={handleEmergencySubmit} isDisabled={emergencyLoading} >
            <input disabled={emergencyLoading} name={"name"} placeholder={emergency.name}  />
            <input disabled={emergencyLoading} name={"phone"}  placeholder={emergency.phone} />
            <input disabled={emergencyLoading} name={"email"}  placeholder={emergency.email}  />
            <button disabled={emergencyLoading} type="submit">{emergency.send}</button>
            <div>
                <label>{emergency.options}</label>
                <a href="https://wa.me/+56962338080?" target="_blank" ><i class="fab fa-whatsapp" aria-hidden="true"></i></a>
                <a href="tel:+56962338080" target="_blank" ><i class="fas fa-phone" aria-hidden="true"></i></a>
            </div>
        </FormContainer>
    )

}