import { RESET_PFP, SET_PERSONAL_DATA_PFP, SET_PICS } from "./PfpConstants";


export const setPersonalDataPfp = (personal) => async (dispatch) => {
    try {
        dispatch({
            type: SET_PERSONAL_DATA_PFP,
            payload: personal
        });

    } catch (error) {
        console.log("error en SET_PERSONAL_DATA", error)
    }
}

export const setPicsPfp = (pics) => async (dispatch) => {
    try {
        dispatch({
            type: SET_PICS,
            payload: pics
        });

    } catch (error) {
        console.log("error en SET_MOTORS_LIST", error)
    }
}

export const resetPfp = () => async (dispatch) => {
    try {
        dispatch({
            type: RESET_PFP
        });

    } catch (error) {
        console.log("error en RESET_PFP", error)
    }
}
