import styled from "styled-components";

export const ModalContainer = styled.div`
    position: fixed;
    top: 56px;
    height: calc(100vh - 56px);
    width: 100%;
    backdrop-filter: brightness(0.5);
    z-index: 500;
    display: ${({active})=>active?"block":"none"};
`