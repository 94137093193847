import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { addCart } from "../../redux/catalogue/CatalogueActions";
import { FormContainer, PdfButton, WpButton } from "./styles";
import { getAnalytics, logEvent } from "firebase/analytics";

export function InputQuantityProducts({product}){
    const analytics = getAnalytics();

    const {cart} = useSelector(({catalogue})=>catalogue)
    const {translate:{catalogue}} = useSelector(({language})=>language)
    const dispatch = useDispatch()

    function handleAddProduct(event,product){
        event.preventDefault()
        let isExistProduct = false;
        const {target:{elements:[input]}}= event
        let products = cart ? cart.map((e)=>{
            if(e.product.id === product.id){
                isExistProduct = true
                return {...e, quantity:parseInt(e.quantity)+parseInt(input.value)}
            }
            return e
        }):[]
        if(!isExistProduct){
            products.push({product,quantity:input.value})
        }
        dispatch(addCart(products))
        input.value = 1
        logEvent(analytics, `addToCart_${product.name}`);
    }

    return(
        <FormContainer content={catalogue.budget} onSubmit={(event)=>handleAddProduct(event,product)}>
            <input name={"quantity"} defaultValue="1" type="number" pattern="[0-9]*" min="1" max="100"/>
            <button type="submit" ><i class="fas fa-cart-plus"></i></button>
            {
            product.attachments.length>0 
                &&
            <PdfButton 
            type="button"
            onClick={
                () =>window.open(process.env.REACT_APP_API+"/product/attachment/"+product.attachments[0].id)
            }
            
            >
                <i class="far fa-file-pdf"></i> 
            </PdfButton>}
            <WpButton
            type="button"
            onClick={
                () =>window.open(`https://api.whatsapp.com/send?text=Mira éste producto: ${window.location.origin}/catalogo?productId=${product.id}`)
            }
            
            >
               <i class="fab fa-whatsapp"></i>

            </WpButton>
        </FormContainer>
    )
}