import axios from 'axios';
import { GET_COUNTRY_CODE } from './Constants'

export const getCountryCode = () => async (dispatch) => {
    try {
        let res= await axios.get('https://ipapi.co/json/')
        
        let {country_name, country_calling_code}= res.data

        dispatch({
            type: GET_COUNTRY_CODE,
            payload: {country_calling_code, country_name}
        });

    } catch (error) {
        console.log("error en getCountryCode", error)
    }
}