
import axios from "axios";
import { SET_ACTIVE_EMERGENCY, SET_EMERGENCY_LOADING, SET_EMERGENCY_SUBMIT, SET_EMERGENCY_RESET } from "./EmergencyConstants";

export const setActiveEmergency = (action) => async (dispatch) => {
    try {
        dispatch({
            type: SET_ACTIVE_EMERGENCY,
            payload:action
        });

    } catch (error) {
        console.log("error en SET_ACTIVE_EMERGENCY", error)
    }
}

export const setEmergencySubmit = (datas) => async (dispatch) => {
    try {

        dispatch(setEmergencyLoading(true))

        const {data:{data}} = await axios.post(`${process.env.REACT_APP_API}/contact/emergency`, datas)
        
        if(data?.success) dispatch({
            type: SET_EMERGENCY_SUBMIT,
            payload:{success: true}
        });
        else dispatch({
            type: SET_EMERGENCY_SUBMIT,
            payload:{success: false}
        });

        dispatch(setEmergencyLoading(false))


    } catch (error) {
        dispatch({
            type: SET_EMERGENCY_SUBMIT,
            payload:{success: false}
        });

        dispatch(setEmergencyLoading(false))
        console.log("error en SET_EMERGENCY_SUBMIT", error)
    }
}

export const setEmergencyLoading = (action) => async (dispatch) => {
    try {
        dispatch({
            type: SET_EMERGENCY_LOADING,
            payload:action
        });

    } catch (error) {
        console.log("error en SET_EMERGENCY_LOADING", error)
    }
}

export const setEmergencyReset = () => async (dispatch) => {
    try {
        dispatch({
            type: SET_EMERGENCY_RESET,
        });

    } catch (error) {
        console.log("error en SET_EMERGENCY_RESET", error)
    }
}