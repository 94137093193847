import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountryCode } from "../redux/Actions";


export const useGetCountryCode = () => {

    const dispatch = useDispatch()

    const [infoCodeCountry, setInfoCodeCountry]= useState({
        country: "",
        celCode: ""
    })

    const countryCode = useSelector((store) => store.reducer.countryCode);

    useEffect(()=>{
        if(countryCode && countryCode.country_name && countryCode.country_calling_code ){
            setInfoCodeCountry({
                country: countryCode.country_name,
                celCode: countryCode.country_calling_code?.slice(1)
            })
        }else{
            dispatch(getCountryCode())
        }
    },[countryCode])


    return{
        infoCodeCountry,
    }
}