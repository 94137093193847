import './App.css';
import { BrowserRouter } from "react-router-dom";
import Routes from './routes';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes />
    </BrowserRouter>
  );
}

export default App;
