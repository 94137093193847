import styled from "styled-components";
import { COLOR_PRIMARY } from "../../styles/global";

export const SuccessContainer = styled.section`
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;

`

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    h1{
        color: ${COLOR_PRIMARY};
        align-self: center;
        margin: 0;
        text-transform: uppercase;
    }
    p{
        font-size: 1rem;
        align-self: center;
        margin-bottom: 2em;
        font-weight: 100;
    }
`

export const ChevronIcon = styled.i`
    font-size: 2em;
    color: white;
    filter: drop-shadow(2px 4px 6px black);
`

export const CarrouselContainer = styled.div`
    padding: 0 40px;
`

export const ClientCard = styled.div`
    cursor: pointer;
    img{
        width: 100%;
    }
`
