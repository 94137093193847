
import { useDispatch, useSelector } from "react-redux"
import { setActiveEmergency } from "../../redux/emergency/EmergencyActions"
import { ButtonContainer, FloatsContainer } from "./styles"

export default function FloatEmergency (){

    const {translate:{emergency}} = useSelector(({language})=>language)

    const dispatch = useDispatch()

    function handleActiveModal(){
        dispatch(setActiveEmergency(true))
    }
    
    return(
        <FloatsContainer >
            <ButtonContainer 
                onClick={handleActiveModal}
            >
                <i class="fas fa-bolt"></i>
                <label>{emergency.floatButton}</label>
            </ButtonContainer>
        </FloatsContainer>
    )
}