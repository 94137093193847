
import { useDispatch, useSelector } from "react-redux";
import { setActiveEmergency } from "../../redux/emergency/EmergencyActions";
import { EmergencyForm } from "../EmergencyForm/EmergencyForm";
import { GlobalInfoModal } from "../GlobalInfoModal/GlobalInfoModal";
import { ModalGlobal } from "../ModalGlobal/ModalGlobal";

export function ModalEmergency(){

    const {activeModal} = useSelector(({emergency})=>emergency)
    const {translate:{emergency}} = useSelector(({language})=>language)

    const dispatch = useDispatch()

    function handleCloseEmergencyModal(){
        dispatch(setActiveEmergency(false))
    }

    return(
        <ModalGlobal active={activeModal} >
            <GlobalInfoModal
            title={emergency.modalTitle}
            content={""}
            success={false}
            extras={<EmergencyForm />}
            closeAction={handleCloseEmergencyModal}
            />
        </ModalGlobal>
    )
}