import React from 'react'
import banner1 from '../../images/banner1.png'
import styles from './index.module.scss'
import { useHistory } from 'react-router'

export default function PowerFactorInfo(){
    const history = useHistory()

    return(
    <div id={styles.infoContainer}>  
        <div>
            <h2>REDUCIR MULTAS POR</h2>
            <h1>MAL FACTOR DE POTENCIA</h1>
            <div>
                <p>
                Las multas económicas no son las únicas consecuencias de un mal factor de potencia. Este fenómeno produce un deterioro prematuro de las redes y del equipamiento eléctrico, así como un mayor gasto en su mantenimiento y operación.
                </p>
                <p>
                En toda instalación eléctrica existen diversas situaciones y fenómenos que ocurren en su interior, dañando (en mayor o menor medida) tanto sus componentes como los equipos y aparatos conectados a ella. El problema es que estos fenómenos muchas veces ocurren silenciosamente, sin que los usuarios se percatan hasta que es demasiado tarde, lo que en el caso del factor de potencia (FP) puede tener consecuencias altamente costosas.
                </p>
                <p>
                El FP se determina a partir de la relación entre las potencias activa y reactiva de los consumos y, dado que la normativa actualmente vigente en el país establece que los consumos deben tener un factor de potencia igual o superior a 0,93, aquellas empresas con un consumo que tenga un factor de potencia inferior a 0,93 inductivo, podrán ser multadas.
                </p>
                <p>
                Estos problemas de FP se observan comúnmente en sectores industriales donde la demanda o aporte de energía reactiva cobra gran relevancia, debido al equipamiento utilizado en los procesos productivos.
                </p>
                <p>
                “En general, todos los equipamientos o instalaciones que demandan potencia eléctrica necesitan un componente de potencia reactiva, siendo aquellas con mayor influencia en el factor de potencia las instalaciones donde existan máquinas eléctricas estáticas, como transformadores de tensión, y máquinas eléctricas rotatorias, como motores eléctricos”
                </p>
                <p>
                Las principales empresas afectadas por presentar consumos con bajo FP, se encuentran las asociadas a la agroindustria (mataderos, frigoríficos, packing) y las empresas frutícolas.
                </p>
                <p>
                “También podemos mencionar en este grupo, por su uso intensivo de motores y transformadores, a las industrias de la madera, celulosa y papel, del cemento, de tratamiento y procesamiento de minerales, y petroquímica”
                </p>
                <p>
                “Desde el punto de vista técnico, problemas en el factor de potencia en procesos industriales afectan directamente la eficiencia operacional y el desempeño óptimo de estos, generando sobredimensionar instalaciones por exceso de reactivos, aumento en las pérdidas en la transferencia de potencia en los conductores que componen el circuito industrial, entre otros, lo cual, como se comentaba anteriormente, genera perjuicios económicos y en el desempeño de los procesos”
                </p>
                <p>
                En otras palabras, un mal FP representa un mayor gasto de (y en) las instalaciones eléctricas, tanto de los usuarios como de las compañías de distribución. No obstante, y pese a la importancia evidente que posee, no todas las organizaciones se preocupan de mantener un factor de potencia “sano”.
                </p>
                <p>
                “Las empresas no le toman importancia alguna al factor de potencia, hasta el minuto en que se genera un cargo adicional en la factura de energía y potencia eléctrica. Aquellas que se preocupan son aquellas que cuentan con personal de planta especializado en el uso eficiente de la energía, o cuando se conciben proyectos de instalación de plantas industriales que traen consigo el equipamiento necesario para el control de la componente reactiva de potencia eléctrica”

                </p>                             
            </div>
            <button  onClick={()=>history.push("/electricbill")} type="button" className={"btn btn-lg "+ styles.boton}>SOLICITAR PRESUPUESTO <i className="fas fa-angle-right"></i></button>
        </div>
        <div>
            <img alt="energia store" src={banner1}  className={"rounded float-right fluid " + styles.imgMotor}></img>
        </div>
    </div>
    )
}


