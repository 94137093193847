import Carrousel from "../../components/Carrousel/Carrousel";
import CarrouselHome from "../../components/CarrouselHome/CarrouselHome";
import Clients from "../../components/Clients/Clients";
import Switch from "../../components/Switch/Switch";


export default function Home(){

    return(
        <>
        <CarrouselHome  name="home"/>
        <Switch page="home"/>
        <div className="container">
            <Carrousel/>
            <Clients/>
        </div>
        </>
    )
}