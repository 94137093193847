export const translate_es ={
    header:{
        electricBill:"Factura eléctrica",
        motorOptimization:"Optimización de motores",
        catalogue:"Productos",
        successCases:"Casos de éxito",
        contact:"Contacto"
    },
    switchw:{
        emergency:"Emergencia",
        bills:"Facturas",
        access:"Acceso"
    },
    carrousel:{
        title:"Productos destacados",
        subtitle:"La mejor selección de productos para tu empresa. Calidad garantizada."
    },
    clients:{
        title:"Empresas que confían en nosotros para optimizar su consumo eléctrico"
    },
    footer:{
        contact:"Contacto",
        contactTo:"Para más información escríbanos a",
        offices:"Sucursales",
        centralOffice:"Casa central",
        company:"Empresa",
        aboutUs:"Sobre nosotros",
        successCases:"Casos éxito",
        saving:"Ahorro",
        access:"Acceso"
    },
    pfp:{
        title:"Reducir consumo de energía",

        companyInfo:"Informacion de la Empresa",
        infoLabel:"Empresa / Institución",

        addBills:"Adjuntar facturas",
        billLabel:"Factura",
        addMoreBills:"Agregar",

        personalInfo:"Información personal",
        nameLabel:"Nombre y Apellido",
        countryLabel:"País",
        optionalLabel:"Opcional",

        quote:"Cotizar",

        errorQuantity:"Debes cargar al menos una factura.",
        errorInputs:"Debes llenar todos los campos.",

        modalSuccessTitle:"Éxito",
        modalSuccessContent:"Recibimos tu información, pronto nos comunicaremos contigo.",
        modalSuccessThanks:"Muchas gracias"
    },
    emo:{

        title:"Optimización de motores eléctricos",

        personalInfo:"Información personal",
        nameLabel:"Nombre y Apellido",
        countryLabel:"País",
        optionalLabel:"Opcional",

        projectInfo:"Información del proyecto",
        projectName:"Nombre del proyecto",
        kwLabel:"Valor KWh (centavos de dólar)",

        addMotorsButton: "Agregar Motores",
        orLabel:"ó",
        excelButton:"Cargar excel",
        downloadExcel:"Descargar plantilla",
        uploadExcel:"Cargar Archivo",

        tableName:"Nombre",
        tableStart:"Partida",
        tablePowerFactor:"Factor de potencia",
        tableDaily:"Uso diario",
        tableMonthly:"Uso Mensual",

        formTitle:"Agregar Motores",
        addMotorPlate:"Adjunte placa de su motor (opcional)",
        motorPlateLabel:"Placa de motor",
        motorName:"Nombre del motor",
        startMode:"Modo de partida",

        startDirect:"Directa / (Y-D)",
        startFrequency:"Variador de frecuencia",
        startSplitter:"Partidor Suave",

        dailyUse:"Horas de uso por día",
        monthlyUse:"Días de uso al mes",
        powerFactor:"Factor de potencia",
        addMotor:"Cargar Motor",

        quoteButton:"Cotizar",
        backButton:"Volver",

        errorInputs:"Debes llenar todos los campos.",
        errorMotorsFile:"Debes cargar al menos un motor.",
        errorQuantityFile:"Debes cargar un archivo.",
        errorFile:"Debes llenar todos los campos correctamente.",
        errorFile_1:"Error en la fila ",
        errorFile_2:", columna ",
        errorFile_3:", valor ",

        modalSuccessTitle:"Éxito",
        modalSuccessContent:"Recibimos tu información, pronto nos comunicaremos contigo.",
        modalSuccessThanks:"Muchas gracias"
    },
    catalogue:{
        title:"Catálogo",
        categories:"Categorías",

        filterShow:"Mostrar",
        filterProducts:"productos",
        search:"Buscar",

        seeAll:"Ver todo",
        
        budget:"Presupuesto",

        addedProducts:"Productos añadidos",

        quantity:"Cantidad",
        name:"Nombre",
        delete:"Eliminar",
        quote:"Cotizar",

        nameForm:"Nombre y Apellido",
        suggestionsForm:"Consultas / Sugerencias",

        send:"Enviar",

        errorInputs:"Debes llenar todos los campos.",

        modalSuccessTitle:"Éxito",
        modalAddedProduct:"Producto añadido!",
        modalSuccessThanks:"¡Muchas gracias!, pronto nos comunicaremos contigo"

    },
    stories:{
        title:"Casos de éxito",
        p_1:"Con más de 11 años de experiencia a nivel internacional atendiendo clientes en los sectores comerciales, industriales y minería, le ofrecemos compromiso, excelencia en servicio, creatividad innovadora, responsable, proveedor de resultados y profesionales de la ingeniería eléctrica.",
        p_2:"He aquí algunos ejemplos de los clientes que confían en nosotros."
    },
    contact:{
        title:"Envianos tu consulta o sugerencia",
        p_1:"Para nosotros lo más importante es estár en contacto.",
        p_2:"Queremos escuchar tus sugerencia y responder a todas tus inquietudes.",
        formName:"Nombre y Apellido",
        vendor:"Distribuidor",
        reunion:"Agendar Reunión",
        consult:"Consulta a Ingenieria",
        suggestions:"Consultas ó Sugerencias",

        errorInputs:"Debes llenar todos los campos.",
        modalSuccessThanks:"Muchas gracias por tu mensaje. Pronto nos comunicaremos contigo"

    },
    about:{
        title:"Sobre Nosotros",
        sub_1:"Nuestra misión",
        sub_2:"Nuestra visión",
        sub_3:"Nuestros valores",
        p_1:"Energía Store Spa es una empresa Chilena dedicada a la eficiencia energética e ingeniería eléctrica, con más de 11 años de experiencia a nivel internacional atendiendo clientes en los sectores comerciales, industriales y minería.",
        p_2:"Transformar Motores Eléctricos en Eficientes Aplicando Tecnología KVAR, la cual alcanza retornos de la inversión menores a 3 años.",
        p_3:"Ser la empresa líder en eficiencia energética a motores eléctricos, para contribuir a bajar la huella industrial de consumos energéticos y ser parte de un mundo mejor.",
        p_4:"Know how internacional, compromiso, excelencia en servicio, creatividad innovadora, responsable, proveedor de resultados y profesionales de la ingeniería eléctrica."
    },
    success:{
        title:"Casos de éxito",
        subtitle:"Conozca algunos de nuestros innumerables proyectos y sus resultados."
    },
    storieSuccess:{
        title:"Casos de éxito",
        downloadButton:"Descargar caso",
        codelco:"Proyecto Implementado: Como parte del Programa de Mejoramiento de la Eficiencia Energética, Confiabilidad de los Equipos y Disminución de los Costos, se instaló como Proyecto Piloto en cada motor de Celdas del Banco 103 de la Flotación Colón, un sistema de condensadores KVARS modelo KEFC10 código SAP 4124850, que optimiza el suministro de Energía generando ahorros en la Facturación por uso de la Energía Eléctrica y recuperación de la instalación. Este Proyecto impacta en Costos, Ambiente y Vida útil de los equipos e instalaciones.",
        engie:"Somos una generadora eléctrica en el norte de Chile que genera 2.100 MW al sistema eléctrico. Durante 2014 y 2015 se llevó a cabo un proyecto de eficiencia energética aplicando equipos KVAR a motores eléctricos, como bombas de agua y ventilador de enfriamiento que funcionan con baja tensión hasta 600 voltios. Destacamos nuestro compromiso social y la ejecución de proyectos de eficiencia energética son vitales para contribuir y cuidar el medio ambiente. La ejecución e instalación del proyecto y todo el proyecto terminó siendo un éxito para la Compañía."
    },
    saving:{
        title:"Ahorro de energía",
        emoSaving:"Ahorro energético en motores eléctricos",
        emo_p:"La generación de energía está limitada y aprovechando la existente simplemente debemos hacer más eficientes los usos.",
        pfpSaving:"Factor de Potencia",
        pfp_p:"Conozca como puede disminuir su consumo eléctrico solo con enviarnos los datos de su última factura.",
        moreInfo:"Más información",
        quote:"Solicitar cotización"
    },
    emergency: {
        floatButton:"Emergencia",
        modalTitle:"Emergencia",
        name:"Nombre",
        phone:"Télefono",
        email:"Email",
        send:"Enviar",
        options:"O contactanos a través de:",
        successTitle:"Muchas gracias, pronto nos comunicaremos contigo",
        errorTitle:"Ocurrió un error, vuelva a intentarlo por favor."
    }
}