import React, { useState } from 'react'
import { ChevronIcon } from './styles'
import ItemsCarousel from 'react-items-carousel';

export function GlobalCarrousel({children, color}){

    const [activeItemIndex, setActiveItemIndex] = useState(0);

    return(
        <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={1}
            gutter={20}
            leftChevron={<ChevronIcon color={color} className={"fas fa-chevron-left"}></ChevronIcon>}
            rightChevron={<ChevronIcon color={color} className={"fas fa-chevron-right"}></ChevronIcon>}
        >
            {children}
        </ItemsCarousel>
    )
}