export const translate_en ={
    header:{
        electricBill:"Electric bill",
        motorOptimization:"Electric motor optimization",
        catalogue:"Products",
        successCases:"Success stories",
        contact:"Contact us"
    },
    switchw:{
        emergency:"Emergency",
        bills:"Bills",
        access:"Access"
    },
    carrousel:{
        title:"Featured products",
        subtitle:"The best selection of products for your company. Guaranteed quality."
    },
    clients:{
        title:"Companies that trust us to optimize their electricity consumption."
    },
    footer:{
        contact:"Contact us",
        contactTo:"For more information write to",
        offices:"Offices",
        centralOffice:"Central office",
        company:"Company",
        aboutUs:"About Us",
        successCases:"Succes stories",
        saving:"Saving",
        access:"Access"
    },
    pfp:{
        title:"Reduce energy consumption",

        companyInfo:"Company information",
        infoLabel:"Company / Institute",

        addBills:"Attach electric bill",
        billLabel:"Bill",
        addMoreBills:"Add",

        personalInfo:"Personal information",
        nameLabel:"Name and LastName",
        countryLabel:"Country",
        optionalLabel:"Optional",

        quote:"Quote",

        errorQuantity:"You must upload at least one invoice.",
        errorInputs:"You must complete all the fields.",

        modalSuccessTitle:"Success",
        modalSuccessContent:"We received your information, we will contact you soon.",
        modalSuccessThanks:"Thank you"
    },
    emo:{

        title:"Electric motor optimization",

        personalInfo:"Personal Information",
        nameLabel:"Name and LastName",
        countryLabel:"Country",
        optionalLabel:"Optional",

        projectInfo:"Project Information",
        projectName:"Project Name",
        kwLabel:"KWh value (US cents)",

        addMotorsButton: "Add Motors",
        orLabel:"or",
        excelButton:"Load excel file",
        downloadExcel:"Download template",
        uploadExcel:"File upload",

        tableName:"Name",
        tableStart:"Start",
        tablePowerFactor:"Power Factor",
        tableDaily:"Daily Use",
        tableMonthly:"Monthly Use",

        formTitle:"Add Motors",
        addMotorPlate:"Attach your motor nameplate (optional)",
        motorPlateLabel:"Motor plate",
        motorName:"Motor name",
        startMode:"Start mode",

        startDirect:"Direct / (Y-D)",
        startFrequency:"Frequency inverter",
        startSplitter:"Soft splitter",

        dailyUse:"Hours of use per day",
        monthlyUse: "Days of use per month",
        powerFactor:"Power factor",
        addMotor:"Add Motor",

        quoteButton:"Quote",
        backButton:"Back",
        
        errorInputs:"You must complete all the fields.",
        errorMotorsFile:"You must load at least one motor.",
        errorQuantityFile:"You must upload a file.",
        errorFile:"You must fill all the fields correctly.",
        errorFile_1:"Error in row ",
        errorFile_2:", column ",
        errorFile_3:", value ",

        modalSuccessTitle:"Success",
        modalSuccessContent:"We received your information, we will contact you soon.",
        modalSuccessThanks:"Thank you"
    },
    catalogue:{
        title:"Catalogue",
        categories:"Categories",

        filterShow:"Show",
        filterProducts:"products",
        search:"Search",

        seeAll:"See All",
        
        budget:"Quote",

        addedProducts:"Products added",

        quantity:"Quantity",
        name:"Name",
        delete:"Delete",
        quote:"Quote",

        nameForm:"Name and lastname",
        suggestionsForm:"Querys / Suggestions",

        send:"Send",

        errorInputs:"You must complete all the fields.",

        modalSuccessTitle:"Success!",
        modalAddedProduct:"Product added!",
        modalSuccessThanks:"¡Thank You!, we will contact you soon."

    },
    stories:{
        title:"Success stories",
        p_1: "With more than 11 years of international experience serving clients in the commercial, industrial and mining sectors, we offer you commitment, excellence in service, innovative creativity, responsible, provider of results and professionals in electrical engineering.",
        p_2: "Here are some examples of customers who trust us."
    },
    contact:{
        title:"Send us your question or suggestion",
        p_1:"For us the most important thing is to be in contact.",
        p_2:"We want to listen to your suggestions and respond to all your concerns.",
        formName:"Name and lastname",
        vendor:"Distributor",
        reunion:"Schedule Meeting",
        consult:"Consult Engineering",
        suggestions:"Queries and Suggestions",

        errorInputs:"You must complete all the fields.",
        modalSuccessThanks:"Thank you very much for your message, we will contact you soon."
    },
    about:{
        title:"About Us",
        sub_1:"Our mission",
        sub_2:"Our vision",
        sub_3:"Our values",
        p_1:"Energía Store Spa is a Chilean company dedicated to energy efficiency and electrical engineering, with more than 11 years of international experience serving clients in the commercial, industrial and mining sectors.",
        p_2:"Transform Electric Motors into Efficient Applying KVAR Technology, which achieves returns on investment of less than 3 years.",
        p_3:"To be the leading company in energy efficiency for electric motors, to help lower the industrial footprint of energy consumption and be part of a better world.",
        p_4:"International know-how, commitment, excellence in service, innovative creativity, responsible, provider of results and professionals in electrical engineering."
    },
    success:{
        title:"Success stories",
        subtitle:"Get to know some of our countless projects and their results."
    },
    storieSuccess:{
        title:"Success stories",
        downloadButton:"Download storie",
        codelco:"Implemented Project: As part of the Program to Improve Energy Efficiency, Equipment Reliability and Cost Reduction, a KVARS condenser system model KEFC10 code was installed as a Pilot Project in each cell motor of Bank 103 of the Colón Flotation. SAP 4124850, which optimizes the supply of Energy generating savings in the Billing for the use of Electric Energy and recovery of the installation. This Project impacts on Costs, Environment and Useful Life of the equipment and facilities. ",
        engie:"We are an electricity generator in the north of Chile that generates 2,100 MW to the electricity system. During 2014 and 2015, an energy efficiency project was carried out applying KVAR equipment to electric motors, such as water pumps and cooling fans that work with low voltage up to 600 volts. We highlight our social commitment and the execution of energy efficiency projects are vital to contribute and take care of the environment. The execution and installation of the project and the entire project ended up being a success for the Company."
    },
    saving:{
        title: "Energy saving",
        emoSaving: "Energy saving in electric motors",
        emo_p: "Power generation is limited and taking advantage of the existing one we simply have to make uses more efficient.",
        pfpSaving: "Power Factor",
        pfp_p: "Find out how you can reduce your electricity consumption just by sending us the details of your last bill.",
        moreInfo: "More information",
        quote: "Request a quote"
    },
    emergency: {
        floatButton:"Emergency",
        modalTitle:"Emergency",
        name:"Name",
        phone:"Phone",
        email:"Email",
        send:"Send",
        options:"Or contact us through",
        successTitle:"¡Thank You!, we will contact you soon.",
        errorTitle:"An error occurred, please try again."
    }
}