
import motor from "../../images/motor_notif.png"
import factura from "../../images/factura_notif.png"
import products from "../../images/products_notif.png"

import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { ButtonContainer, FloatsContainer } from "./styles"

export default function Floats (){
    const motorsList = useSelector(({emo})=>emo.motorsList)
    const pics = useSelector(({pfp})=>pfp.pics)
    const cart = useSelector(({catalogue})=>catalogue.cart)

    const history = useHistory()


    function handleFloatCatalogue(){
        if(history.location.pathname==="/catalogo"){
            
            const cart = document.getElementById("productsCart")
            const menu = document.getElementsByClassName("menuCatalogue")[0].offsetHeight

            if(cart.clientHeight ===cart.firstElementChild.clientHeight){
                cart.firstElementChild.click()
            }
            window.scroll(0,cart.offsetTop-menu)
        }else{
            
            history.push("/catalogo",{float:true})
        }
    }

    
    return(
        <FloatsContainer >
            {motorsList 
            &&
            motorsList.length > 0
            &&
            <ButtonContainer 
            onClick={()=>history.push("/optimizacion")}
            >
                <img src={motor}/>
                <label>{motorsList.length}</label>
            </ButtonContainer>
            }
            {pics && Object.keys(pics).length >0 &&
            <ButtonContainer 
            onClick={()=>history.push("/electricbill")}
            >
                <img src={factura}/>
                <label>{Object.keys(pics).length}</label>
            </ButtonContainer>
            }
            {cart &&
            <ButtonContainer 
            onClick={handleFloatCatalogue}
            >
                <img src={products}/>
                <label>{cart.length}</label>
            </ButtonContainer>
            }
        </FloatsContainer>
    )
}