import React from 'react';
import { ModalContainer } from './styles';


export function ModalGlobal({children, active}){
    
    return(
        <ModalContainer active={active} >
            {active && children}
        </ModalContainer>
    )
}