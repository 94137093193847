import Banner from "../../components/Banner/Banner";
import Clients from "../../components/Clients/Clients";
import Stories from "../../components/Stories/Stories";
import Success from "../../components/Success/Success";
import Switch from "../../components/Switch/Switch";
import TitleSection from "../../components/TitleSection/TitleSection";
import casos from "../../images/casos.png";
import {  useSelector } from "react-redux"

export default function CasosExito(){
    const {translate:{stories}} = useSelector(({language})=>language)
    
    return(
        <>
        <Banner image={casos}/>
        <TitleSection name={stories.title} />
        <div className="container">
            <Stories translate={stories}/>
            <Success/>
            <Clients/>
        </div>
        <Switch page="ahorro"/>
        </>
    )
}
