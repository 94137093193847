import React from "react";
import { useDispatch } from "react-redux";
import { setProductDetail } from "../../redux/catalogue/CatalogueActions";
import { GlobalCarrousel } from "../GlobalCarrousel/GlobalCarrousel";
import { InputQuantityProducts } from "../InputQuantityProducts/InputQuantityProducts";
import { ProductModalContainer, ImageContainer, CarrouselContainer, ChevronIcon, CloseButton } from "./styles";
import imageNotFound from "../../images/imageNotFound.PNG"

export function ProductInfoModal({product}){

    const dispatch =useDispatch()
    function handleCloseModal(){
        dispatch(setProductDetail(null))
    }
    return(product &&
        <ProductModalContainer>
            <CarrouselContainer>
                <GlobalCarrousel>
                    { product.images.length >0 && product.images.map((e)=>(
                    <ImageContainer>
                   {
                        e?.video?
                        <video autoPlay controls src={e.url} />
                        :
                        <img 
                        alt={`product ${product.name}`}
                        src={
                            e
                            ?
                            process.env.REACT_APP_API+"/product/image/"+e.id
                            :
                            imageNotFound
                        } 
                        />
                    }           
                    </ImageContainer>
                    )
                    )}
                </GlobalCarrousel>
            </CarrouselContainer>
            <div>
                <CloseButton onClick={handleCloseModal}><i class="far fa-times-circle"></i></CloseButton>
                <h1>{product.name}</h1>
                <p>{product.description}</p>
                <InputQuantityProducts product={product} />
            </div>
        </ProductModalContainer>
    )
}