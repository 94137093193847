import Banner from "../../components/Banner/Banner";
import ElectricMotorInfo from "../../components/Information/ElectricMotorInfo";
import Success from "../../components/Success/Success";
import TitleSection from "../../components/TitleSection/TitleSection";
import Clients from "../../components/Clients/Clients";
import emo from "../../images/emo.png";

export default function OptimizacionInfo(){

    return(
        <>
        <Banner image={emo}/>
        <TitleSection name="" />
        <div className="container">
            <ElectricMotorInfo />
            <Success/>
            <Clients/>
        </div>
        </>
    )
}