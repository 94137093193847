export const RESET_CATALOGUE="RESET_CATALOGUE";
export const ADD_CART="ADD_CART";
export const DELETE_CART="DELETE_CART";
export const SET_PRODUCTS= "SET_PRODUCTS";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_PRODUCT_DETAIL= "SET_PRODUCT_DETAIL"
export const CLOSE_PRODUCT_MODAL= "CLOSE_PRODUCT_MODAL"
export const ERROR_CONTACT_CATALOGUE= "ERROR_CONTACT_CATALOGUE"
export const SUCCESS_SUBMIT_MODAL= "SUCCESS_SUBMIT_MODAL"

