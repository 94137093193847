import { SET_ACTIVE_EMERGENCY, SET_EMERGENCY_SUBMIT, SET_EMERGENCY_LOADING, SET_EMERGENCY_RESET } from "./EmergencyConstants";

const initialState = {
   activeModal:false,
   isSubmitted:null,
   emergencyLoading:false
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_ACTIVE_EMERGENCY:
        return {
            ...state,
            activeModal:action.payload
        }  
    case SET_EMERGENCY_SUBMIT:
        return {
            ...state,
            isSubmitted:action.payload
        }  

    case SET_EMERGENCY_LOADING:
        return {
            ...state,
            emergencyLoading:action.payload
        }  

    case SET_EMERGENCY_RESET:
    return initialState
        
    default:
    return state;
} 
};