
import FloatEmergency from "../FloatEmergency/FloatEmergency";
import Floats from "../Floats/Floats";
import Header from "../Header/Header";
import { ModalEmergency } from "../ModalEmergency/ModalEmergency";


export function ExtraRoutes(){

    

    return(
        <>
        <Header />
        <Floats />
        <FloatEmergency />
        <ModalEmergency />
        </>
    )
}