import styles from "./index.module.scss"
export default function TitleSection({name, setSearch, setProductsPerPage, productsPerPage,translate, handleOnClick, isCatalogue}){

    function handleSearch(e){
        setSearch(e.target.value)
    }

    function handleProductsPerPage(e){
        setProductsPerPage(parseInt(e.target.name))
    }

    return(
        <div id={styles.title_section} class={isCatalogue && styles.isCatalogue+" menuCatalogue"}>
            {
                isCatalogue
            ?
                <button
                className={"btn "+styles.categoryButton}
                onClick={handleOnClick}>
                    {translate.categories}
                </button>
            :
                <h3>{name}</h3>
            }
            {
            isCatalogue
            && 
            <>
                <div class="btn-toolbar m-3 justify-content-center" role="toolbar" aria-label="Toolbar with button groups">
                    
                    <div class="btn-group me-2 align-items-center" role="group" aria-label="First group">
                       
                        <label className="fw-bolder" >{translate.filterShow}</label>

                        <button name="10" type="button" class={ productsPerPage===10?`${styles.quantityButton} ${styles.quantityButton_active}`: styles.quantityButton} onClick={handleProductsPerPage}>10</button>
                        <button name="20" type="button" class={ productsPerPage===20?`${styles.quantityButton} ${styles.quantityButton_active}`: styles.quantityButton}  onClick={handleProductsPerPage}>20</button>
                        <button name="40" type="button" class={ productsPerPage===40?`${styles.quantityButton} ${styles.quantityButton_active}`: styles.quantityButton}  onClick={handleProductsPerPage}>40</button>
                       
                        <label>{translate.filterProducts}</label>

                    </div>

                </div>

                <form 
                className="d-flex" 
                onSubmit={(e)=>{
                    e.preventDefault()
                    setSearch(e.target[0].value)
                }}>
                    <input className="form-control me-2" onChange={handleSearch} type="search" placeholder={translate.search} aria-label="Search"/>
                    <button className="btn btn-outline-success"  type="submit">{translate.search}</button>
                </form>
            </>
            }
		</div>
    )
}