import Banner from "../../components/Banner/Banner";
import Posts from "../../components/Post/Post";
import Success from "../../components/Success/Success";
import Switch from "../../components/Switch/Switch";
import TitleSection from "../../components/TitleSection/TitleSection";
import Clients from "../../components/Clients/Clients";
import bann_1 from "../../images/bann_1.png"
import {  useSelector } from "react-redux"

export default function Ahorro(){
    const {translate:{saving}} = useSelector(({language})=>language)

    return(
        <>
        <Banner image={bann_1}/>
        <TitleSection name={saving.title} />
        <div class="container">
            <Posts />
            <Success/>
            <Clients/>
        </div>

        <Switch page="ahorro"/>
        </>
    )
}