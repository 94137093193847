import React, {  useEffect,  useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import {  setErrorContactCatalogue, setCategories, setProductDetail, setProducts, setSuccessSubmit, deleteCart } from "../../redux/catalogue/CatalogueActions";
import { InputQuantityProducts } from "../InputQuantityProducts/InputQuantityProducts";
import { GlobalCarrousel } from "../GlobalCarrousel/GlobalCarrousel";
import {  AccordionTable, Card, CardsContainer, CarrouselContainer, CatalogueContainer, CategoriesMenuContainer, CategoryButton, ContactForm, ImageContainer, PaginationContainer, ProductInfoContainer } from "./styles";
import { GlobalAccordion } from "../GlobalAccordion/GlobalAccordion";
import { COLOR_SECONDARY, COLOR_SUCCESS } from "../../styles/global";
import { useLocation } from "react-router";
import imageNotFound from "../../images/imageNotFound.PNG"
import { getAnalytics, logEvent } from "firebase/analytics";

export function contactValidation(input){
    let error= {}
    if(!input.name) error.name= true
    if(!input.email) error.email= true
    if(input.email){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!(re.test(input.email.toLowerCase()))) error.email = true
    }
    if(!input.suggestions) error.suggestions= true
    return error
}

export default function Catalogue({search, productsPerPage, menu, setMenu }){
    const dispatch = useDispatch()
    const analytics = getAnalytics();

    const {products, categories, cart} = useSelector(({catalogue})=>catalogue)
    const {translate:{catalogue}, activeLanguage} = useSelector(({language})=>language)

    const [categorias, setCategorias] = useState(null)
    const location = useLocation()

    useEffect(()=>{
        if(!categories)dispatch(setCategories())
        if(!products)dispatch(setProducts() )
        if(location.state?.float){
            const cart = document.getElementById("productsCart")
            cart.firstElementChild.click()
            const menu = document.getElementsByClassName("menuCatalogue")[0].offsetHeight
            setTimeout(()=>{
                window.scroll(0,cart.offsetTop-menu)
            },1500)
        }
        
    },[])

    useEffect(()=>{
        if(location.search){
            const searchId= location.search.split("=")[1]

            if(location.search.includes("categoryId")){
                const categorieFilter = categories?.filter((cat)=>cat.id===Number(searchId))
                categorieFilter && setCategorias(categorieFilter[0])
            }else{
                const filterSearch = products?.filter((item)=>item.id === Number(searchId))    
                filterSearch && dispatch(setProductDetail(filterSearch[0]))  
            }
        }
    },[products, categories])


    
    function handleDeleteProduct(id){
        const filtrado = cart.filter((e)=>e.product.id !==id)
        dispatch(deleteCart(filtrado.length>0?filtrado:null))
    }

    const [contact, setContact] = useState({name:"",email:"", suggestions:""})

    function handleContactChange(e){
        setContact({...contact,[e.target.name]:e.target.value})
    }

    async function handleContactSubmit(e){
        e.preventDefault()
        let validation=contactValidation(contact)
        if(Object.keys(validation).length>0) return dispatch(setErrorContactCatalogue(true))
        dispatch(setSuccessSubmit(contact,cart))
        setContact({name:"",email:"", suggestions:""})
    }

    const [page,setPage] = useState(1)
    const [pageQuantity, setPageQuantity] = useState(Math.floor(products?.length/productsPerPage))
    const [pagination, setPagination] = useState([])

    useEffect(()=>{
        let productos = products;
        if(categorias && products){
            productos = productos.filter((e)=>{
                let found = false;
                for(let i = 0; i < e.categories.length; i++) {
                    if (e.categories[i].id == categorias.id) {
                        found = true;
                        break;
                    }
                }
                return found;
             }
            )
        } 
        setPageQuantity(Math.ceil(productos?.length/productsPerPage))
        let aux =productos?.slice(page*productsPerPage-productsPerPage, page*productsPerPage)
        setPagination(aux)
    },[page,products, productsPerPage, categorias])

    useEffect(()=>{
        setPage(1)
    },[productsPerPage,categorias])

    function handleChangePage(aux){
        if(aux === "left"){
            if(page>1) setPage(page-1)
        }
        else if(aux === "right"){
            if(page<pageQuantity) setPage(page+1)
        }
        else setPage(aux)
    }

    const[buttons, setButtons] = useState([])

    useEffect(()=>{
        let aux = []
        for (let i = 1; i <= pageQuantity; i++) {
            aux.push(
                <li key={i} ><button onClick={()=>handleChangePage(i)}>{i}</button></li>
            )  
        }
        setButtons(aux)
    },[pageQuantity])
    


    function handleProductDetail(product,event){
        const tag=event.target.tagName; 
        if(tag!=="INPUT"&&tag!=="BUTTON"&&tag!=="IMG"&&tag!=="I"){
            logEvent(analytics, product.name);
            dispatch(setProductDetail(product))
        }
    }

    function handleAddedProductModal(){
        dispatch(setProductDetail(null))
    }

    useEffect(()=>{
        document.onkeydown = function (evt) {
            if (evt.keyCode == 27) {
                handleAddedProductModal()
            }
        };
    },[])

    useEffect(()=>{
        if(search){      
            let arr = products.filter((e)=>{
                if( e.description && e.description.toLowerCase().includes(search.toLowerCase())) return true
                if(e.name &&e.name.toLowerCase().includes(search.toLowerCase())) return true 
                if(e.specSheetText&&e.specSheetText.toLowerCase().includes(search.toLowerCase())) return true
            })
            setPagination(arr)
        }
        else{
            let aux =products?.slice(page*productsPerPage-productsPerPage, page*productsPerPage)
            setPagination(aux)
        }
    },[search])
  

    useEffect(()=>{
        window.removeEventListener("click",handleCloseCategories);
        window.addEventListener("click", handleCloseCategories);
        return()=>{
            window.removeEventListener("click",handleCloseCategories);
        }
    },[catalogue])

    function handleCloseCategories(e){
        if(e.target.innerHTML !== catalogue.categories){
            setMenu(false);
        }
    }

    function handleCategorie(e){
        if(e.id==="seeAll") setCategorias(null)
        else setCategorias(e)
        setMenu(!menu)
    }

    
    return(
        <CatalogueContainer >
            <CategoriesMenuContainer open={menu}>
               {
                categories && categories.map((e)=>(
                    <div key={e.id}>
                        <CategoryButton 
                            onClick={()=>handleCategorie(e)} 
                            isActive={categorias?.id===e.id}
                        >
                            <label>{
                                e.id==="seeAll"
                                ?
                                catalogue.seeAll
                                :
                                activeLanguage==="es"
                                ?
                                e.name_es
                                :
                                e.name
                            }
                            </label>
                            {
                            e.id!=="seeAll"
                            &&
                            <a href={`https://api.whatsapp.com/send?text=Mira ésta categoría: ${window.location.origin}/catalogo?categoryId=${e.id}`} target="_blank" ><i class="fab fa-whatsapp" aria-hidden="true"></i></a>
                            }
                        </CategoryButton>
                    </div>
                ))
               }
            </CategoriesMenuContainer>
          
            <CardsContainer>
            {
                pagination?.length>0 && pagination.map((product)=>{
                    return(
                        <Card key={product.id}>
                                <CarrouselContainer >
                                    <GlobalCarrousel color={"#42b07975"} >
                                        {
                                            product.images.length > 0 && product.images.map((e)=>{
                                                return <ImageContainer>
                                                            {
                                                                e?.video?
                                                                <video autoPlay muted  controls src={e.url} />
                                                                :
                                                            <img 
                                                            alt={`product ${product.name}`}
                                                            src={
                                                                    e
                                                                    ?
                                                                    process.env.REACT_APP_API+"/product/image/"+e.id
                                                                    :
                                                                    imageNotFound
                                                                } 
                                                                />
                                                            }
                                                        </ImageContainer>
                                            })
                                        }

                                    </GlobalCarrousel>
                                </CarrouselContainer>
                                <ProductInfoContainer onClick={(event)=>handleProductDetail(product,event)}>
                                        <h5>{product.name}</h5>
                                        <p>{product.description}</p>
                                        <InputQuantityProducts product={product} />
                                </ProductInfoContainer>

                        </Card>

                    )
                })
            }
            </CardsContainer>

            <PaginationContainer aria-label="Pagination">
                <ul >
                    <li ><button onClick={()=>handleChangePage("left")} >{"<"}</button></li>
                    {
                        buttons.length>0 &&buttons
                    }
                    <li ><button onClick={()=>handleChangePage("right")} >{">"}</button></li>
                </ul>
            </PaginationContainer>

            <GlobalAccordion 
                id="productsCart"
                disabled={!cart?.length}
                buttonColor={COLOR_SECONDARY}
                buttonLabel={`${catalogue.addedProducts} ${cart?cart.length:0}`}>
                <AccordionTable>
                    <thead>
                        <tr>
                            <th>{catalogue.quantity}</th>
                            <th>{catalogue.name}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {
                            cart?.map((e,i)=>{
                                return(
                                    <tr key={e.product.id}>
                                        <td scope="row">{e.quantity}</td>
                                        <td onClick={(event)=>handleProductDetail(e.product,event)} >{e.product.name}</td>
                                        <th onClick={()=>handleDeleteProduct(e.product.id)}>{catalogue.delete}</th>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </AccordionTable>
            </GlobalAccordion>
            <GlobalAccordion 
                disabled={!cart?.length}  
                buttonLabel={catalogue.quote}
                buttonColor={COLOR_SUCCESS}
            >
                <ContactForm onSubmit={handleContactSubmit} >
                    <input 
                        type="text" 
                        value={contact.name} 
                        name="name"
                        onChange={handleContactChange} 
                        placeholder={catalogue.nameForm} 
                        aria-label="Complete name"
                    />
                    <input 
                        type="email" 
                        value={contact.email}  
                        name="email" 
                        onChange={handleContactChange} 
                        placeholder="Email" 
                        aria-label="Email"
                    />
                    <textarea 
                        name="suggestions" 
                        value={contact.suggestions} 
                        onChange={handleContactChange} 
                        placeholder={catalogue.suggestionsForm} 
                    />
                    <button 
                        
                    >
                        {catalogue.send}
                    </button>
                </ContactForm>
            </GlobalAccordion>
        </CatalogueContainer>
    )
}