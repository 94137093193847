
import styled from "styled-components";

export const EXTRA_DESKTOP="990px"
export const DESKTOP = "768px";
export const TABLET = "425px";

export const COLOR_PRIMARY = "#42B079"
export const COLOR_SECONDARY= "#007799"
export const COLOR_SUCCESS="#C73061"
export const COLOR_LIGHT="#E0E0E0"



export const Spinner = styled.div`

    border: ${({color=COLOR_PRIMARY})=>"4px solid "+color};
    border-left-color: transparent;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    display: inline-block;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

`