import Banner from "../../components/Banner/Banner";
import Clients from "../../components/Clients/Clients";
import TitleSection from "../../components/TitleSection/TitleSection";
import PowerFactorInfo from "../../components/Information/PowerFactorInfo";

export default function Optimizacion(){

    return(
        <>
        <Banner/>
        <TitleSection name="" />
        <div className="container">
            <PowerFactorInfo />
            <Clients/>
        </div>
        </>
    )
}