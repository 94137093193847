import { SET_PERSONAL_DATA, SET_MOTORS_LIST, RESET_EMO, USE_FILE } from "./EmoConstants";


export const setPersonalDataEmo = (personal) => async (dispatch) => {
    try {
        dispatch({
            type: SET_PERSONAL_DATA,
            payload: personal
        });

    } catch (error) {
        console.log("error en SET_PERSONAL_DATA", error)
    }
}

export const setMotorsListEmo = (motor) => async (dispatch) => {
    try {
        dispatch({
            type: SET_MOTORS_LIST,
            payload: motor
        });

    } catch (error) {
        console.log("error en SET_MOTORS_LIST", error)
    }
}

export const resetEmo = () => async (dispatch) => {
    try {
        dispatch({
            type: RESET_EMO
        });

    } catch (error) {
        console.log("error en RESET_EMO", error)
    }
}

export const setUseFile = (data) => async (dispatch) => {
    try {
        dispatch({
            type: USE_FILE,
            payload:data
        });

    } catch (error) {
        console.log("error en USE_FILE", error)
    }
}