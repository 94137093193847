import React, { useEffect, useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import styles from "./index.module.scss"
import { useHistory } from 'react-router';
import {  useDispatch, useSelector } from "react-redux"
import { setProductDetail, setProducts } from '../../redux/catalogue/CatalogueActions';
import imageNotFound from "../../images/imageNotFound.PNG"

export default function Carrousel() {
  const dispatch = useDispatch()

  const {translate:{carrousel}} = useSelector(({language})=>language)
  const {products} = useSelector(({catalogue})=>catalogue)

  const history =useHistory()
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const [tamaño, setTamaño]= useState({ windowWidth: window.innerWidth })
  const [nCards, setNCards] = useState(5)
  const handleResize = (e) => {
    setTamaño({ windowWidth: window.innerWidth });
   };

  useEffect(()=>{
    window.addEventListener("resize", handleResize);
    return()=>{
      window.removeEventListener("resize", handleResize);
    }
  },[])

  useEffect(()=>{
    if(tamaño.windowWidth < 510) setNCards(1)
    else if(tamaño.windowWidth < 770) setNCards(2)
    else if(tamaño.windowWidth < 995) setNCards(3)
    else if(tamaño.windowWidth < 1200) setNCards(4)
    else setNCards(5)
  },[tamaño])

  useEffect(()=>{
    if(!products)dispatch(setProducts())
  },[])

  function handleSelectProduct(product){
    history.push("/catalogo")
    dispatch(setProductDetail(product))
  }

  return (
      <div id={styles.carrousel} className="d-flex flex-column">
        <h1 onClick={()=>history.push("/catalogo")} >{carrousel.title}</h1>
        <p className="lead">{carrousel.subtitle}</p>
        <div style={{ padding: `0 ${chevronWidth}px` }}>
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={nCards}
                gutter={20}
                leftChevron={<i className={"fas fa-chevron-left "+styles.icon_carousel}></i>}
                rightChevron={<i className={"fas fa-chevron-right "+styles.icon_carousel}></i>}
                outsideChevron
                chevronWidth={chevronWidth}
            >
               { products && products.map((e,i)=>{
                   return(
                        <div onClick={()=>handleSelectProduct(e)} key={i} className={"card border-0 "+styles.card_carousel}>
                            <img 
                            alt="energia store" 
                            src={
                              e.images[0]
                              ?
                              process.env.REACT_APP_API+"/product/image/featured/"+e.id
                              :
                              imageNotFound
                            } 
                            className="card-img-top" />
                            <div className="card-body">
                                <h6 className="card-title">{e.name}</h6>
                            </div>
                        </div>
                        )
                     }
                   )
                }
                
            </ItemsCarousel>
        </div>
    </div>
  );
};