import React from 'react'
import { useHistory } from 'react-router'
import styles from './footer.module.scss'
import {  useSelector } from "react-redux"


export default function Footer(){
	const history = useHistory()
    const {translate:{footer}} = useSelector(({language})=>language)

	return(

	<footer className="mainfooter" role="contentinfo">
			<div className="container">
				<div className="row justify-content-md-center">
					{/* <!--Column1--> */}
					<div className="col  md-6 lg-6 xl-6">
						<div id={styles.contacto} className="footer-pad">
							<h2>{footer.contact}</h2>
							<ul className={"list-unstyled "+styles.contactList}>
								<li><h5><a className={styles.whats} href="
								https://wa.me/+56962338080?
								"><i className="fab fa-whatsapp"></i> +569 6233 8080</a></h5></li>
								<li><h5><a className={styles.whats} href="tel:+56962338080"><i class="fas fa-phone"></i>+ 569 6233 8080</a></h5></li>
								<li><h5><a className={styles.skype} target="_blank" href="https://join.skype.com/invite/q2GfIX1ceP5d"> <i className="fab fa-skype"></i> JOHNFOUERE</a></h5></li>
								<li><h6><a className={styles.envelope} href="mailto:info@energiastore.com">{footer.contactTo}<p> <i className="far fa-envelope"></i> info@energiastore.com</p></a></h6></li>
							</ul>
						</div>
						<div className="col-md-3">
							<ul className={styles.social_network + " " + styles.social_circle}>
								<li><a href="https://www.facebook.com/energiastore" target="_blank" className="icoFacebook" title="Facebook"><i className="fab fa-facebook"></i></a></li>
								<li><a href="https://www.linkedin.com/company/energiastore" target="_blank" className="icoLinkedin"  title="Linkedin"><i className="fa fa-linkedin"></i></a></li>
							</ul>			
					</div>
				</div>
				{/* <!--Column 2 --> */}
				<div className="col  lg-6 xl-6">
					<div id={styles.sucursales} className="footer-pad">
						<div>
							<h2>{footer.offices}</h2>
							<ul className="list-unstyled" >
								<li>{footer.centralOffice}- Chile  </li>
								<li>USA </li>
								<li>- Argentina </li>
								<li>- México </li>
								<li>- Panamá </li>
								<li>- Perú </li>
								<li>- Emiratos Árabes </li>
								<li>- India </li>
								<li>- Botswana </li>
								<li>- Guatemala </li>
								<li>- Brasil </li>
								<li>- Colombia </li>
								<li>- Sudáfrica </li>
							</ul>
						</div>
						<div>
							<h2>{footer.company}</h2>
							<div>
								<a onClick={()=>history.push("/empresa")}>{footer.aboutUs}</a>
								<a onClick={()=>history.push("/casos")}>{footer.successCases}</a>
								<a onClick={()=>history.push("/ahorro")}>{footer.saving}</a>
								<a href={`${process.env.REACT_APP_ADMIN}`} target='_blank' >{footer.access}</a>
							</div>
						</div>
					</div>
				</div>
				</div>
					<div className="row">
						<div className={"col-md-12 " + styles.copy}>
							<p >&copy; Copyright 2020 - Energia Store.  All rights reserved.</p>
{/* 							<a href="http://www.innova3x.com/" target="_blank">Web Site creada por INNOVA</a>
 */}						</div>
					</div>
				</div>
		</footer>
)
}
