import styled from "styled-components";
import { COLOR_PRIMARY, TABLET } from "../../styles/global";

export const ImageHome = styled.img`
    max-height: 22rem;
    width: 100%;
    object-fit: fill;
    cursor: pointer;
    height: 100%;
`
export const ArrowIcon = styled.i`
    font-size:4rem;
    width: 6rem;
    color: ${COLOR_PRIMARY};
    text-align: center;
    @media only screen and (max-width:${TABLET}){
        font-size: 2rem;
        width: 3rem
    }
`

export const CarrouselHomeContainer = styled.section`
    margin-top: 56px;
    height:min-content;
`


