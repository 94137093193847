import { RESET_CATALOGUE, ADD_CART, SET_PRODUCTS, SET_CATEGORIES, SET_PRODUCT_DETAIL,CLOSE_PRODUCT_MODAL, ERROR_CONTACT_CATALOGUE, SUCCESS_SUBMIT_MODAL, DELETE_CART } from "./CatalogueConstants";

const initialState = {
   cart:null,
   products:null,
   categories:null,
   productDetail:null,
   addProductModal:null,
   errorContactCatalogue:null,
   successSubmitModal:null
};

  export default (state = initialState, action) => {
     switch (action.type) {

        case ADD_CART:
            return {
                ...state,
                addProductModal:true,
                cart: action.payload
            }
        case DELETE_CART:
            return {
                ...state,
                cart: action.payload
            }
        case CLOSE_PRODUCT_MODAL:
            return{
                ...state,
                addProductModal:false
            }
        case SET_PRODUCTS:
            return {    
                ...state,
                products: action.payload
            }
        case SET_CATEGORIES:
            return {    
                ...state,
                categories: action.payload
            }
        case SET_PRODUCT_DETAIL:
            return {
                ...state,
                productDetail:action.payload
            }
        case ERROR_CONTACT_CATALOGUE:
            return {
                ...state,
                errorContactCatalogue:action.payload
            }   
        case SUCCESS_SUBMIT_MODAL:
            return {
                ...state,
                successSubmitModal:action.payload
            }   
        case RESET_CATALOGUE:
            return {
                ...state,
                cart:null,
                productDetail:null,
                addProductModal:null,
                errorContactCatalogue:null,
                successSubmitModal:null
            }
      default:
        return state;
    } 
  };