import axios from "axios";
import { DELETE_CART, ADD_CART, RESET_CATALOGUE, SET_CATEGORIES, SET_PRODUCTS, SET_PRODUCT_DETAIL, CLOSE_PRODUCT_MODAL, ERROR_CONTACT_CATALOGUE, SUCCESS_SUBMIT_MODAL } from "./CatalogueConstants";
import { collection, deleteDoc, doc, getDocs, getFirestore, setDoc } from "@firebase/firestore";
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from "@firebase/storage";

export const addCart = (cart) => async (dispatch) => {
    try {
        dispatch({
            type: ADD_CART,
            payload: cart
        });

    } catch (error) {
        console.log("error en ADD_CART", error)
    }
}

export const deleteCart = (cart) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_CART,
            payload: cart
        });

    } catch (error) {
        console.log("error en DELETE", error)
    }
}


export const closeProductModal = () => async (dispatch) => {
    try {
        dispatch({
            type: CLOSE_PRODUCT_MODAL
        });

    } catch (error) {
        console.log("error en CLOSE_PRODUCT_MODAL", error)
    }
}


export const resetCatalogue = () => async (dispatch) => {
    try {
        dispatch({
            type: RESET_CATALOGUE
        });

    } catch (error) {
        console.log("error en RESET_CATALOGUE", error)
    }
}

async function handleAlgo(id){
    const db = await getFirestore()
    const clientsRef = await collection (db, `products/${id}/videos`)
    const querySnapshot = await getDocs(clientsRef);
    const arr = []
    querySnapshot.forEach((doc) => {
        arr.push({...doc.data(), id:doc.id, video:true})
    });
    return arr
}

export const setProducts = () => async (dispatch)=>{
    try {

        const {data:{elements}} = await axios.get(process.env.REACT_APP_API+"/product?_where={%22onlyActive%22:true}")
        const arr = elements.map(async (product)=>{
            let imageFeatured
            let imagesNotFeatured = product.images.filter((image)=>{
                if(image.featured) imageFeatured =image;
                return !image.featured
            })
            imagesNotFeatured.unshift(imageFeatured)
            product.images = imagesNotFeatured
            const nArr = await handleAlgo(product.id)
            nArr.length>0&& product.images.push(...nArr)
            return product
        })
        const result = await Promise.all(arr)
        dispatch({
            type: SET_PRODUCTS,
            payload:result
        });

    } catch (error) {
        console.log("error en SET_PRODUCTS", error)
    }
}


export const setCategories = () => async (dispatch)=>{
    try {

        let {data:{elements}} = await axios.get(process.env.REACT_APP_API+"/category/")

        elements=[...elements,{id: "seeAll"}]
        dispatch({
            type: SET_CATEGORIES,
            payload:elements
        });

    } catch (error) {
        console.log("error en SET_CATEGORIES", error)
    }
}

export const setProductDetail = (product) => async (dispatch)=>{
    try {        
        dispatch({
            type: SET_PRODUCT_DETAIL,
            payload:product
        });

    } catch (error) {
        console.log("error en SET_PRODUCT_DETAIL", error)
    }
}

export const setErrorContactCatalogue = (type) => async (dispatch)=>{
    try {        
        dispatch({
            type: ERROR_CONTACT_CATALOGUE,
            payload:type
        });

    } catch (error) {
        console.log("error en ERROR_CONTACT_CATALOGUE", error)
    }
}

export const setSuccessSubmit = (contact,cart,type=true) => async (dispatch)=>{
    try {      
        if(type){
            await axios.post(process.env.REACT_APP_API+"/order/submit",
            {
                email:contact.email,
                invoicing:{
                    city: "",
                    country: "",
                    firstName: contact.name,
                    lastName: "",
                    postalCode: "",
                    province: "",
                    streetAddress: ""
                },
                paymentMethod:"",
                products:cart,
                shipping:{
                    city: "",
                    country: "",
                    firstName: contact.name,
                    lastName: "",
                    postalCode: "",
                    province: "",
                    streetAddress: ""
                },
                smt:true,
                userId:"",
                suggestions: contact.suggestions
            })
        }
        dispatch({
            type: SUCCESS_SUBMIT_MODAL,
            payload:type
        });
        if(!type){
            dispatch({
                type: RESET_CATALOGUE
            });
        }

    } catch (error) {
        console.log("error en SUCCESS_SUBMIT_MODAL", error)
    }
}