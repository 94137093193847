
import { SET_CLIENTS, SET_CLIENTS_DETAIL, SET_CLIENTS_LOADING } from './ClientsConstants';
import { getFirestore, collection,getDocs } from "firebase/firestore"

export const setClients = () => async (dispatch) => {
    try {
        await dispatch(setClientsLoading(true));

        const db = getFirestore()
        const clientsRef = collection(db, "clients")

        const querySnapshot = await getDocs(clientsRef);
        const clientsArr = []
        querySnapshot.forEach((doc) => {
            clientsArr.push({...doc.data(), id:doc.id})
        });

        dispatch({
            type: SET_CLIENTS,
            payload: clientsArr
        });
        await dispatch(setClientsLoading(false));
    } catch (error) {
        console.log("error en SET_CLIENTS", error)
    }
}

export const setClientsLoading = (type) => async (dispatch) => {
    try {        
        dispatch({
            type: SET_CLIENTS_LOADING,
            payload: type
        });

    } catch (error) {
        console.log("error en SET_CLIENTS_LOADING", error)
    }
}

export const setClientDetail = (client) => async (dispatch) => {
    try {        
        dispatch({
            type: SET_CLIENTS_DETAIL,
            payload: client
        });

    } catch (error) {
        console.log("error en SET_CLIENTS_DETAIL", error)
    }
}