import { RESET_PFP, SET_PERSONAL_DATA_PFP, SET_PICS,  } from "./PfpConstants";

const initialState = {
   personal:null,
   pics:null,
  };

  export default (state = initialState, action) => {
     switch (action.type) {
        case SET_PERSONAL_DATA_PFP:
            return {
            ...state,
            personal: action.payload
            }
        case SET_PICS:
            return {
                ...state,
                pics: action.payload
            }
        case RESET_PFP:
            return {
                personal:null,
                pics:null
            } 
      default:
        return state;
    } 
  };