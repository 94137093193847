import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import CatalogueReducer from './catalogue/CatalogueReducer';
import EmoReducer from './emo/EmoReducer';
import LanguageReducer from './language/LanguageReducer';
import PfpReducer from './pfp/PfpReducer';
import rootReducer from './Reducer';
import EmergencyReducer from './emergency/EmergencyReducer';
import ClientsReducer from './clients/ClientsReducer';

const reducer = combineReducers({
    reducer: rootReducer,
    emo:EmoReducer,
    pfp:PfpReducer,
    catalogue:CatalogueReducer,
    language:LanguageReducer,
    emergency: EmergencyReducer,
    clients: ClientsReducer
})
//REDUX DETVOOLS
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    composeEnhancer(applyMiddleware(thunk))
);

export default store;