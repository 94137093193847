import Banner from "../../components/Banner/Banner";
import Clients from "../../components/Clients/Clients";
import Contact from "../../components/Contact/Contact";
import contact_bann from "../../images/contact_bann.png";

export default function Contacto(){

    return(
        <>
        <Banner image={contact_bann}/>
        <div className="container">
            <Contact />
            <Clients/>
        </div>
        </>
    )
}