import React,{ useEffect, useState} from 'react'
import { useHistory } from 'react-router'
import ESIsologoblanco from "../../images/ESisologoblanco.svg"
import style from "./index.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { setActiveLanguage } from '../../redux/language/LanguageActions';
import {Helmet} from "react-helmet";
import { getAnalytics, logEvent } from "firebase/analytics";


export default function Header(){ 
	const history = useHistory()
	const dispatch = useDispatch()
	const {activeLanguage, translate:{header}} = useSelector(({language})=>language)
	const[show,setShow] = useState(true)

	const analytics = getAnalytics();
	
	function handleRoute(e){
		logEvent(analytics, e.target.name);
		setShow(false)
		history.push("/"+e.target.name)
	}

	useEffect(()=>{
		var ignoreClickOnMeElement = document.getElementById(style.header);
		document.addEventListener("click", (evt) => {
			var isClickInsideElement = ignoreClickOnMeElement.contains(evt.target);
				if (!isClickInsideElement) {
					setShow(!show)
				}
				setShow(show)
		});
		document.addEventListener('scroll', function(e) {
			setShow(!show)
			setShow(show)
		  });
	},[])



	function handleLanguage({target}){
		setShow(false)
		dispatch(setActiveLanguage(target.name))
	}

	return(
		<>
		<Helmet>
		<html lang={activeLanguage} amp />

		</Helmet>
		<nav name="navegacion" id={style.header} value={"algo"} name={"algo"} className="navbar navbar-expand-lg navbar-light text-white navegador">
			<div className="container-fluid">
				<img alt="energia store" src={ESIsologoblanco} className="navbar-brand text-white" onClick={()=>history.push("/")} ></img>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
				</button>
				<div className={show?"asd collapse navbar-collapse justify-content-end collapse":" das collapse navbar-collapse justify-content-end collapse"} id="navbarSupportedContent">
					<ul className="navbar-nav mb-2 mb-lg-0">

						{/* <li className={history.location.pathname==="/ahorro"?"nav-item "+style.labelActive:"nav-item" }>
						<a className={"nav-link text-white "} name="ahorro" onClick={handleRoute}>Ahorro de Energia</a>
						</li> */}
						<li className={history.location.pathname==="/electricbill"?"nav-item "+style.labelActive:"nav-item" }>
						<a className="nav-link text-white" name="electricbill" onClick={handleRoute}>{header.electricBill}</a>
						</li>
						<li className={history.location.pathname==="/optimizacion"?"nav-item "+style.labelActive:"nav-item" }>
						<a className="nav-link text-white" name="optimizacion" onClick={handleRoute}>{header.motorOptimization}</a>
						</li>
						{/* <li className={history.location.pathname==="/iluminacion"?"nav-item "+style.labelActive:"nav-item" }>
						<a className="nav-link text-white" name="iluminacion" onClick={handleRoute}>Iluminacion</a>
						</li> */}
						{/* <li className={history.location.pathname==="/solar"?"nav-item "+style.labelActive:"nav-item" }>
						<a className="nav-link text-white" name="solar" onClick={handleRoute}>Solar</a>
						</li> */}
						<li className={history.location.pathname==="/catalogo"?"nav-item "+style.labelActive:"nav-item" }>
						<a className="nav-link text-white" name="catalogo" onClick={handleRoute}>{header.catalogue}</a>
						</li>
						<li className={history.location.pathname==="/casos"?"nav-item "+style.labelActive:"nav-item" }>
						<a className="nav-link text-white" name="casos" onClick={handleRoute}>{header.successCases}</a>
						</li>
						<li className={history.location.pathname==="/contacto"?"nav-item "+style.labelActive:"nav-item" }>
							<a className="nav-link text-white" name="contacto" onClick={handleRoute}>{header.contact}</a>
						</li>
						<li className={"nav-item "+style.langCont}>
							<a className={activeLanguage==="es"?"nav-link text-white "+style.actives:"nav-link text-white"} name="es" onClick={handleLanguage}>Es</a>
							<a className={activeLanguage==="en"?"nav-link text-white "+style.actives:"nav-link text-white"} name="en" onClick={handleLanguage}>En</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		</>
	)
}