import React from 'react'
import clients_bann from "../../images/clients_bann.jpg";
import clients_bann_child from "../../images/3.png";

import styles from './Clients.module.scss'
import {  useSelector } from "react-redux"

const Clients = () =>{

    const {translate:{clients}} = useSelector(({language})=>language)


    return (
        <div id={styles.clients}>
            <h2>{clients.title}</h2>
            <img alt="energia store" src={clients_bann} className={"img-fluid "+styles.big} alt="Responsive" />
            <img alt="energia store" src={clients_bann_child} className={"img-fluid "+styles.child} alt="Responsive" />
        </div>
    )
} 


export default Clients;
