import { translate_en } from "../../utils/translate_en";
import { translate_es } from "../../utils/translate_es";
import {SET_ACTIVE_LANGUAGE} from "./LanguageConstants"

const ESlang = localStorage.getItem("ESlang")
let activeLanguage;
let translate;
if(ESlang){
    activeLanguage = ESlang
    translate = ESlang==="es"?translate_es: translate_en
}else{
    localStorage.setItem("ESlang","es")
    activeLanguage = "es"
    translate = translate_es
}

const initialState = {
   activeLanguage,
   translate
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_ACTIVE_LANGUAGE:
        return {
            ...state,
            activeLanguage: action.payload.activeLanguage,
            translate: action.payload.translate
        }  
    default:
    return state;
} 
};