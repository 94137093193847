import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setClients } from "../redux/clients/ClientsActions";

export function useGetSuccessStories(){
    const dispatch = useDispatch()
    const {loading, clients} = useSelector(({clients})=>clients)

    useEffect(()=>{
        if(!clients){
            dispatch(setClients()) 
        }
    },[])

    return {loading, clients}
}