import styled from "styled-components";
import { COLOR_LIGHT, COLOR_SUCCESS, COLOR_SECONDARY, DESKTOP, EXTRA_DESKTOP, TABLET, COLOR_PRIMARY } from "../../styles/global";

export const CatalogueContainer = styled.section`
    padding: .1rem 3rem;
    background-color:#f3f3f3;
    position: relative;
    
    @media only screen and (max-width:${EXTRA_DESKTOP}){
        padding: 2rem;
    }
    @media only screen and (max-width:${TABLET}){
        padding: .5rem;
    }
    
`


export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    img{
        object-fit: contain;
        height: 100%;
        width:fill-available;
        @media only screen and (max-width:${TABLET}){
            max-height: 15rem;
        }
    }
    video{
        object-fit: contain;
        width:100%;
        @media only screen and (max-width:${TABLET}){
            max-height: 15rem;
        }
    }

`

export const CarrouselContainer = styled.div`
    >div{
        height:100%;
        >div:first-child{
            overflow: hidden;
            height:100%;
            div{
            height:100%;
            }
        }
    }
`

export const CategoriesMenuContainer = styled.div`
    width: 15rem;
    position: fixed;
    z-index: 100;
    height: calc(100vh - 56px);
    left: -15rem;
    background-color: white;
    transition: all 1s;
    transform: ${({open})=>open?"translateX(15rem)":"translateX(0)"};
    top: 126px;
    @media only screen and (max-width:528px){
        top: 208px;
    }
`

export const CategoryButton = styled.button`
    display: flex;
    border: none;
    outline: none!important;
    width: 100%;
    padding: .5rem;
    text-transform: uppercase;
    background-color:${({isActive})=>isActive?COLOR_SUCCESS:"transparent"};
    font-weight:${({isActive})=>isActive?500:100};
    color:${({isActive})=>isActive?"#fff":"#000"};
    label{
        width: 100%;
    }
    a{
        i{
            color:${COLOR_PRIMARY};
            :hover{
                font-weight: 800;
            }
        }
    }
    :hover{
        backdrop-filter: brightness(.95);
    }
`

export const CardsContainer = styled.div`
    width: 100%;
    display: grid;
    gap: 1rem;
    //grid-template-columns: repeat(auto-fit,minmax(min(100%,17rem),1fr));
    grid-template-columns: repeat(auto-fit,minmax(min(100%,25rem),1fr));
    grid-auto-rows:  12rem;
    justify-content:center;
    margin-top: ${()=>(document.getElementsByClassName("menuCatalogue")[0]?.offsetHeight+"px")};
    @media only screen and (max-width:${TABLET}){
        grid-auto-rows:  auto;
    }

`


export const Card = styled.div`
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
    background: white;
    padding: 1rem;
    border-radius: 6px;
    :hover{
        transition: box-shadow .5s;
        box-shadow: 0px 0px 20px grey;
    }
    @media only screen and (max-width:${TABLET}){
        grid-template-columns: 100%;
        grid-template-rows: 1fr auto;
    }
`

export const ProductInfoContainer = styled.div`
    padding: 0 .5rem;
    display: flex;
    flex-direction: column;
    p{
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`

export const PaginationContainer = styled.nav`
    ul{
        list-style: none;
        display: flex;
        margin: 0;
        padding: 1rem;
        justify-content: center;
        li{
            button{
                background: #fff;
                border: 1px solid ${COLOR_LIGHT};
                outline: none;
                color:${COLOR_SECONDARY};
                border-radius: 6px;
                padding: .5rem;
                :hover{
                    box-shadow: inset 0px 0px 2px grey;
                }
            }
        }
    }
`

export const AccordionTable = styled.table`

    width: 100%;
    background: #fff;
    border-radius: 6px;
    thead{
        color:${COLOR_SECONDARY};
        border-bottom: 1px solid ${COLOR_SECONDARY};
        tr{
            th{
                text-align:center;
                padding: .5rem 0;
            }
        }
    }
    tbody{
        tr{
            border-bottom: 1px solid #000;
            td{
                text-align:center;
                padding: .5rem 0;
                :nth-child(2){
                    cursor: pointer;
                    :hover{
                        color:${COLOR_SUCCESS};
                    }
                }
            }
            th{
                cursor: pointer;
                :hover{
                    color:${COLOR_SUCCESS}
                }
            }
            &:last-child{
                border-bottom: none;
            }
        }
    }

`

export const ContactForm = styled.form`
    display: grid;
    grid-template-columns: auto;
    gap:1rem;
    padding: 1rem;
    input{
        padding: .5rem;
    }
    textarea{
        padding: .5rem;
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 3;
        resize: none;
    }
    button{
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 3;
        background-color:${COLOR_SECONDARY};
        outline: none;
        border: none;
        padding: .5rem;
        border-radius: 6px;
        color: #fff;
    }
    @media only screen and (max-width:${TABLET}){
        grid-template-columns: 100%;
       textarea{
            grid-column-start: auto;
            grid-column-end: auto;
       }
       button{
            grid-column-start: auto;
            grid-column-end: auto;
       }
    }
`