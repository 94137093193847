import styled from "styled-components";
import { DESKTOP } from "../../styles/global";

export const ProductModalContainer = styled.section`
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 100%;
    margin: 0 1rem;
    height: 100%;
    background: #fff;
    @media only screen and (max-width:${DESKTOP}){
        grid-template-columns: 100%;
        grid-template-rows: 70% 30%;
        position:relative;
        margin: 0;
        padding:1rem;
    }
`
export const ImageContainer = styled.div`
    max-height: 30rem;
    max-width: 40rem;
    video{
        height: 100%;
        width: 100%;
    }
    img{
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
`

export const CarrouselContainer = styled.div`
    margin:2rem;
    div{
        height:100%
    }
`



export const CloseButton = styled.button`
    background: none;
    border: none;
    outline: none!important;
    width: 100%;
    text-align-last: end;
    i{
        font-size: 2rem;
        margin: 1rem;
    }

    @media only screen and (max-width:${DESKTOP}){
        position: absolute;
        top: 0;
    }
`