import { useHistory } from "react-router"
import motores from "../../images/motores.png"
import post_1 from "../../images/post_1.png"

import styles from "./index.module.scss"
import {  useSelector } from "react-redux"

export default function Posts(){
    const {translate:{saving}} = useSelector(({language})=>language)

    const history = useHistory()

    return(
        <section id={styles.posts}>
            <div className="card">
                <div className={"row g-0 flex-row-reverse"}>
                    <div className="col-md-6">
                        <img alt="energia store" src={motores} />
                    </div>
                    <div className="col-md-6">
                    <div className="card-body">
                        <h2 className="card-title">{saving.emoSaving}</h2>
                        <p className="card-text">{saving.emo_p}</p>
                        <button type="button" onClick={()=>history.push("/optimizacion/info")} className="btn border-none">{saving.moreInfo}<i className="fas fa-plus"></i></button>
                        <button type="button" onClick={()=>history.push("/optimizacion")}  className="btn">{saving.quote}<i className="fas fa-angle-right"></i></button>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="row g-0">
                    <div className="col-md-6">
                        <img alt="energia store" src={post_1} />
                    </div>
                    <div className="col-md-6">
                    <div className="card-body">
                        <h2 className="card-title">{saving.pfpSaving}</h2>
                        <p className="card-text">
                            {saving.pfp_p}
                        </p>
                        <button type="button" onClick={()=>history.push("/electricbill/info")} className="btn border-none">{saving.moreInfo} <i className="fas fa-plus"></i></button>
                        <button type="button" onClick={()=>history.push("/electricbill")} className="btn">{saving.quote}<i className="fas fa-angle-right"></i></button>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
}