import { SET_PERSONAL_DATA, SET_MOTORS_LIST, RESET_EMO, USE_FILE } from "./EmoConstants";

const initialState = {
   personal:null,
   motorsList:null,
   fileUse:null
  };

  export default (state = initialState, action) => {
     switch (action.type) {
        case SET_PERSONAL_DATA:
            return {
            ...state,
            personal: action.payload
            }
        case SET_MOTORS_LIST:
            return {
                ...state,
                motorsList: action.payload
            }
        case RESET_EMO:
            return {
                personal:null,
                motorsList:null
            }
        case USE_FILE:{
            return {
                ...state,
                fileUse:action.payload
            }
        }
  
      default:
        return state;
    } 
  };