import { useEffect, useState } from "react";
import ItemsCarousel from 'react-items-carousel';
import { useSelector } from "react-redux";
import { ArrowIcon, CarrouselHomeContainer, ImageHome } from "./styles";
import { useHistory } from 'react-router'


export default function CarrouselHome(){
  const newArr = ["","contacto","optimizacion","electricbill","catalogo","contacto","catalogo","catalogo","catalogo"]
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth<540)
  const {activeLanguage} = useSelector (({language})=>language)
  const history = useHistory()
  useEffect(()=>{
      const t = setTimeout(()=>{
        if(newArr.length <= activeItemIndex+1) setActiveItemIndex(0)
        else setActiveItemIndex(activeItemIndex+1)
      },6000)
      return ()=>clearTimeout(t)
  })

    useEffect(()=>{
        window.addEventListener("resize",handleResize)
        return ()=>{
            window.removeEventListener("resize",handleResize)
        }
    },[])

    function handleResize() {
        if(window.innerWidth<540){
            setScreenWidth(true)
        }else{
            setScreenWidth(false)
        }
    }

	return(
    <CarrouselHomeContainer>
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={1}
                gutter={20}
                leftChevron={<ArrowIcon onClick={()=>setActiveItemIndex(activeItemIndex-1)} className={"fas fa-chevron-left"}></ArrowIcon>}
                rightChevron={<ArrowIcon onClick={()=>setActiveItemIndex(activeItemIndex+1)}  className={"fas fa-chevron-right"}></ArrowIcon>}
            >
                {
                    newArr.map((e,i)=>{
                        if(screenWidth){
                            return <ImageHome 
                                    onClick={()=>history.push("/"+e)}
                                    src={`./${i+1}_${activeLanguage.toUpperCase()}_M.jpg`}
                                />
                        }
                        return <ImageHome 
                                    onClick={()=>history.push("/"+e)}
                                    src={`./${i+1}_${activeLanguage.toUpperCase()}.jpg`}
                                />
                    })
                }
            </ItemsCarousel>
    </CarrouselHomeContainer>
	)
}