import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home/Home";
import Empresa from "../pages/Empresa/Empresa";
import Generacion from "../pages/Generacion/Generacion";
import Ahorro from "../pages/Ahorro/Ahorro";
import Iluminacion from "../pages/Iluminacion/Iluminacion";
import Catalogo from "../pages/Catalogo/Catalogo";
import Contacto from "../pages/Contacto/Contacto";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Optimizacion from "../pages/Optimizacion/Optimizacion";
import PowerFactor from "../pages/PowerFactor/PowerFactor";
import PowerFactorInfo from "../pages/PowerFactorInfo/PowerFactorInfo";

import CasosExito from "../pages/CasosExito/CasosExito";
import Caso from "../pages/Caso/Caso";
import OptimizacionInfo from "../pages/OptimizacionInfo/OptimizacionInfo";
import Solar from "../pages/Solar/Solar";

import Floats from "../components/Floats/Floats";
import FloatEmergency from "../components/FloatEmergency/FloatEmergency";
import { ModalGlobal } from "../components/ModalGlobal/ModalGlobal";
import { GlobalInfoModal } from "../components/GlobalInfoModal/GlobalInfoModal";
import { EmergencyForm } from "../components/EmergencyForm/EmergencyForm";
import { ExtraRoutes } from "../components/ExtraRoutes/ExtraRoutes";

export default ()=>{

    return(<>
    <Switch>
        {/* <Route exact path="/solar" component={Solar}/> */}
        <Route path="/" render={()=>(
            <>
            <ExtraRoutes />
            
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/empresa" component={Empresa}/>
                <Route exact path="/generacion" component={Generacion }/>
                <Route exact path="/ahorro" component={Ahorro }/>
                <Route exact path="/optimizacion" component={Optimizacion}/>
                <Route exact path="/optimizacion/info" component={OptimizacionInfo}/>
                <Route exact path="/electricbill" component={PowerFactor}/>
                <Route exact path="/electricbill/info" component={PowerFactorInfo }/>
                
{/*                 <Route exact path="/iluminacion" component={Iluminacion}/>
 */}                
                <Route exact path="/catalogo" component={Catalogo}/>
                <Route exact path="/contacto" component={Contacto}/>
                <Route exact path="/casos" component={CasosExito}/>
                <Route exact path="/casos/:id" component={Caso}/>
            </Switch>
            < Footer/>
            
            </>

        )}/>

    
        </Switch>
        </>
    )

}