import styles from "./index.module.scss"

export default function Stories({translate}){
    return (
        <section id={styles.stories}>
            <div className={styles.description}>
                <p>{translate.p_1}</p>
                <p>{translate.p_2}</p>
            </div>
        </section>
    )
}