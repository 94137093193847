import React, { useEffect } from 'react'
import { InfoModalContainer } from './styles'

export function GlobalInfoModal({success=true,title,content,extras, closeAction}){

    useEffect(()=>{
        const element = document.getElementsByClassName(InfoModalContainer.styledComponentId)[0].firstElementChild
        function handleClose(e){
            if (!element.contains(e.target)) closeAction()
        }
        document.addEventListener('click', handleClose);
        return ()=>document.removeEventListener('click', handleClose);
    },[])


    return(
        <InfoModalContainer 
            isSuccess={success} 
        >
            <section>
                <h1>
                    <i className={"fas fa-exclamation-circle"}></i>
                    {title}
                </h1>
                <p>{content}</p>
                <div>{extras}</div>
            </section>
        </InfoModalContainer>
    )
}