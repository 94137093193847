import { useEffect, useState } from "react"
import styles from "./index.module.scss"
import { countries } from "../../utils/countries"
import { useGetCountryCode } from "../../hooks/useGetCountryCode"
import axios from "axios"
import {  useSelector } from "react-redux"

export function infoValidation(input){
    let error={}
    if(!input.name) error.name = true
    if(!input.email) error.email = true
    if(!input.comments) error.comments = true
    return error
}

export default function Contact (){

    const {translate:{contact}} = useSelector(({language})=>language)
    let type=[
        {name:"Distribuidor",value:contact.vendor},
        {name:"Agendar Reunión",value:contact.reunion},
        {name:"Consulta a Ingenieria",value:contact.consult},
        ]
    const [info, setInfo]=useState({name:"",email:"",comments:"", country:"", phone:"", type:type[0].name})
    const [errorInfo, setErrorInfo] = useState({error:true})
    const [infoModal, setInfoModal] = useState(false)
    const [infoSuccessModal, setInfoSuccessModal] = useState(false)

   const {infoCodeCountry} = useGetCountryCode()

    useEffect(()=>{ 
            setInfo({
                ...info,
                country: infoCodeCountry.country,
                phone: infoCodeCountry.celCode
            })
    },[infoCodeCountry])


    function handleInfoChange(e){
        setInfo({...info,[e.target.name]:e.target.value})
    }
     function handleInfoSubmit(e){
        e.preventDefault()
        let valid = infoValidation(info)
        setErrorInfo(valid)
        if(Object.keys(valid).length >0) return setInfoModal(true)
        setInfo({name:"",email:"",comments:"", country:infoCodeCountry.country, phone:infoCodeCountry.celCode})
        setInfoSuccessModal(true)
        axios.post(process.env.REACT_APP_API+"/contact", info )
        
    }

    return(
        <section id={styles.contact}>  
            <div>
                <h1>{contact.title}</h1>
                <h6>{contact.p_1}</h6>
                <h6>{contact.p_2}</h6>
                <h2><i className="fab fa-whatsapp" ></i><a href="https://wa.me/+56962338080?">+569 6233 8080</a></h2>
                <form onSubmit={handleInfoSubmit}>
                    <div className="mb-3">
                        <input type="text" className="form-control" placeholder={contact.formName} id="exampleInputEmail1" name="name" value={info.name} onChange={handleInfoChange} />
                    </div>
                    <div className="mb-3">
                        <input type="email" className="form-control" placeholder="Email" id="exampleInputPassword1" name="email" value={info.email} onChange={handleInfoChange}/>
                    </div>
                    <div className="mb-3">
                        <select value={info.country} onChange={handleInfoChange} className="form-control" name="country" id="country" >
                            <option disabled selected>Selecciona una país</option>
                            {countries.map((e,i)=><option key={e+i} value={e}>{e}</option>)}
                        </select>
                    </div>
                    <div className={"mb-3 "+styles.WhatsApp}>
                        {/* <input type="number" pattern="[0-9]*" className="form-control" name="phone" onChange={handleInfoChange} id="phone" placeholder="Teléfono" value={info.phone}/> */}
                        <label for="phone">+</label>
                        <input type="number" name="phone" pattern="[0-9]*" onChange={handleInfoChange}  className="form-control" id="phone"  value={info.phone}/>
                    </div>
                    

                    <div className="mb-3 w-100">
                        <select value={info.type} onChange={handleInfoChange} className="form-control" name="type" id="type" >
                            {type.map(({name,value},i)=><option key={i} value={name}>{value}</option>)}
                        </select>
                    </div>
                    <div className={"mb-3 "+styles.textArea}>
                        <textarea className="form-control" placeholder={contact.suggestions} id="floatingTextarea" name="comments" value={info.comments} onChange={handleInfoChange} ></textarea>
                    </div>


                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
            <div onClick={()=>setInfoModal(false)} className={`${styles.modal} ${infoModal&&styles.active} ` }>
                    <div>
                        <h3>{contact.errorInputs}</h3>
                    </div>
            </div>
            <div onClick={()=>setInfoSuccessModal(false)} className={`${styles.modal} ${infoSuccessModal&&styles.active} ` }>
                    <div>
                        <h3>{contact.modalSuccessThanks}</h3>
                    </div>
            </div>
        </section>
    )
}