import { useEffect, useState } from "react"
import styles from "./index.module.scss"
import axios from "axios"
import { countries } from "../../utils/countries"
import { useDispatch, useSelector } from 'react-redux';
import { useGetCountryCode } from "../../hooks/useGetCountryCode";
import { resetPfp, setPersonalDataPfp, setPicsPfp } from "../../redux/pfp/PfpActions";
import { useHistory } from 'react-router'


export function infoValidation(input){
    let error= {}
    if(!input.name) error.name = true
    if(!input.email) error.email = true
    if(input.email){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!(re.test(input.email.toLowerCase()))) error.email = true
    }
    if(!input.country) error.country = true
    //if(!input.celPhone) error.celPhone = true
    if(!input.projectName) error.projectName = true
    return error
}

export function penaltyValidation(input){
    let error= {}
    if(!input.penaltyAmt) error.penaltyAmt = true
    if(input.penaltyAmt &&input.penaltyAmt<1) error.penaltyAmt = true
    if(!input.month) error.month = true
    if(input.month){
        let year = input.month.slice(0,4)
        let month =  input.month.slice(5)
        let date = new Date()
        if(year<2000 || year>date.getFullYear()) error.month = true
    }
    return error
}

export default function PFactorForm(){

    const history = useHistory()

    const dispatch = useDispatch()
    const personalData = useSelector(({pfp})=>pfp.personal)
    const picsData = useSelector(({pfp})=>pfp.pics)
    const {translate:{pfp}} = useSelector(({language})=>language)

    const[personal, setPersonal] = useState(
        {
            name:"",
            email:"",
            country:"",
            celPhone:"",
            projectName:"",
        }
    )



    const[personalError, setPersonalError] = useState({error:true}) 
    const[personalModal,setPersonalModal] = useState(false) 
    const [quoteModal,setQuoteModal] = useState(false)
    
    function handlePersonal(e){
        setPersonal({...personal,[e.target.name]:e.target.value})
    }
    const {infoCodeCountry} = useGetCountryCode()

    useEffect(()=>{
        if(personalData){
            setPersonal(personalData)
        }
        if(picsData){
            setPics(picsData)
        }
    },[])

    useEffect(()=>{ 
            setPersonal({
                country: infoCodeCountry.country,
                celPhone: infoCodeCountry.celCode,
                ...personalData
            })
    },[infoCodeCountry,personalData])

    
    async function handleQuoteSubmit(){
        setQuoteModal(true)
        
        let aux = []
        for (const pic in pics) {
            aux.push(pics[pic]);
        }
         await axios.post(process.env.REACT_APP_API+"/project/create",
         {
                name:personal.projectName,
                email:personal.email,
                country:personal.country,
                quotationInformation:{
                    phone: personal.celPhone.toString(),
                    name: personal.name
                },
                kWCost:Number(0),
                type:"PFP",
                lines:aux         
            }
        ) 
        dispatch(resetPfp())

    }
    function handleFinish(){
        setQuoteModal(false)
        setPersonal(
                {
                    name:"",
                    email:"",
                    country: infoCodeCountry.country,
                    celPhone: infoCodeCountry.celCode,
                    projectName:""
                }
            )
        setPersonalError({error:true})
        setPics([])
        handleTopMotor()
        history.push("/")

    }
            
            const [pics, setPics] = useState([])
            const [picsQuantity, setPicsQuantity] = useState([1,2,3])
            const [contador, setContador] = useState(4)
            const [refresh, setRefresh] = useState(true)
            
            function handleAddPic(e,i){    
                let reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = function () {
                    setPics({...pics, [i]:{image: reader.result}})
                    setRefresh(!refresh)
                    dispatch(setPersonalDataPfp(personal))
                    dispatch(setPicsPfp({...pics, [i]:{image: reader.result}}))
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            }
            
            function handleDelete(e){
                if(picsQuantity.length===1)  return;
                let auxi= pics
                delete auxi[e]
                setPics(auxi)
                dispatch(setPicsPfp(auxi))
                let aux= picsQuantity.filter((p)=>p!==e)
                setPicsQuantity(aux)
            }
            
            const [picsModal, setPicsModal] =useState(false)
            
            function handlePicsSubmit(){
                if(pics.length===0) return setPicsModal(true)
                let validation = infoValidation(personal)
                setPersonalError(validation)
                if(Object.keys(validation).length>0) return setPersonalModal(true)
                handleQuoteSubmit()
                
            }
            
            function handleTopMotor(){
                let aux = document.getElementById(styles.contact_form);
                var topPos = aux.offsetTop;
                window.scrollTo(0,topPos-20);
            }
            
            function handleAddMorePics(){
                setPicsQuantity([...picsQuantity,contador])
                setContador(contador+1)
            }
            

                
                return(
                    <div id={styles.contact_form} >
                    <div className={styles.personalContainer}>


                    <div  className={styles.personalForm}>
                    <h4><i class="fas fa-info-circle"></i>{pfp.companyInfo}</h4>
                    <div className="mb-3">
                        <label for="projectName" className="form-label">{pfp.infoLabel}</label>
                        <input type="text" name="projectName" onChange={handlePersonal} className={personalError.projectName?"form-control is-invalid":"form-control"} id="projectName" placeholder="Energy store" value={personal.projectName}/>
                    </div>

                    <div  className={styles.picturesContainer}>
                        <h4><i class="fas fa-info-circle"></i>{pfp.addBills}</h4>
                        {
                            picsQuantity.map((e,i)=>(
                                <div className="input-group mb-3">
                                    <input type="file" accept="image/png, image/gif, image/jpeg" className="form-control" id={"inputGroupFile0"+e} name={"pic"+e} onChangeCapture={(a)=>handleAddPic(a,e)}/>
                                    <label className="input-group-text" for={"inputGroupFile0"+e}>
                                    <button onClick={()=>handleDelete(e)}><i class="far fa-times-circle"></i></button>
                                    {!pics[e] ?<i className="fas fa-camera"></i>
                                        :
                                            <img src={pics[e].image}/>
                                    }
                                    <p>{pfp.billLabel} {i+1}</p>
                                    </label>
                                </div>
                            ))


                        }
                        <div className={styles.buttonsPicsContainer}>    
                            <button type="button" className="btn" onClick={()=>handleAddMorePics()}>
                                {pfp.addMoreBills}
                                <i class="fas fa-plus-circle"></i>
                            </button>
                        </div>
                    </div>
                </div>


                <div className={styles.personalForm}>
                    <h4><i class="fas fa-info-circle"></i>{pfp.personalInfo}</h4>
                    <div className="mb-3">
                        <label for="name" className="form-label">{pfp.nameLabel}</label>
                        <input type="text" name="name" onChange={handlePersonal} className={personalError.name?"form-control is-invalid":"form-control"} id="name" placeholder="Joe Doe" value={personal.name}/>
                    </div>

                    <div className="mb-3">
                        <label for="email" className="form-label">Email</label>
                        <input type="email" name="email" onChange={handlePersonal} className={personalError.email?"form-control is-invalid":"form-control"} id="email" value={personal.email}/>
                    </div>

                    <div className="mb-3">
                        <label for="country" className="form-label">{pfp.countryLabel}</label>
                        <select name="country" value={personal.country} id="country" onChange={handlePersonal} className={personalError.country?"form-select is-invalid":"form-select"}>
                            {countries.map((e,i)=><option key={e+i} value={e}>{e}</option>)}
                        </select>
                    </div>

                    <div className={"mb-3 "+styles.WhatsApp}>
                    <label for="celPhone" className="form-label">WhatsApp ({pfp.optionalLabel})</label>
                        <label for="celPhone">+</label>
                        <input  type="number" pattern="[0-9]*" name="celPhone" onChange={handlePersonal} className={personalError.celPhone?"form-control is-invalid":"form-control"} id="celPhone" value={personal.celPhone}/>
                    </div>
                    <div className={styles.buttonsContainer}>
                            <button type="button" className="btn" onClick={handlePicsSubmit}>
                                {pfp.quote}
                                <i className="fas fa-arrow-right"></i>
                            </button>
                    </div>
                </div>
                
                <div onClick={()=>{
                    setPersonalModal(false)
                    handleTopMotor()
                    }} className={`${styles.modal} ${personalModal&&styles.active} ` }>
                    <div>
                        <label><i class="fas fa-exclamation-circle"></i>ERROR</label>
                        <h3>{pfp.errorInputs}</h3>
                    </div>
                </div>
                <div onClick={()=>{
                    handleTopMotor()
                    setPicsModal(false)
                    }} className={`${styles.modal} ${picsModal&&styles.active} ` }>
                    <div>
                        <label><i class="fas fa-exclamation-circle"></i>ERROR</label>
                        <h3>{pfp.errorQuantity}</h3>
                    </div>
                </div>
                <div onClick={handleFinish} 
                    className={`${styles.modal} ${quoteModal&&styles.active} ` }>
                    <div>
                        <p><i class="fas fa-exclamation-circle"></i>{pfp.modalSuccessTitle}</p>
                        <h3>{pfp.modalSuccessContent}</h3>
                        <h1>{pfp.modalSuccessThanks}</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

