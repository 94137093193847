import styles from "./index.module.scss"
import {  useSelector } from "react-redux"

export default function Storie(){
    const {activeLanguage} = useSelector (({language})=>language)
    const {clientDetail} = useSelector(({clients})=>clients)


    return (
        <section id={styles.stories}>
            <>
                <div className={styles.description}>
                <h2>
                    {clientDetail.name.toUpperCase()}
                </h2>
                <p>{clientDetail[`description_${activeLanguage}`]}</p>
                {clientDetail.pdf && <a 
                type="button" 
                href={clientDetail.pdf.url} 
                target="_blank" 
                className="btn btn-primary">Descargar Caso <i className="fas fa-arrow-circle-down"></i>
                </a>}
                </div>
                <div className={styles.cardsContainer}>
                <img alt="energia store" src={clientDetail.image.url}/>
                </div>
            </>
        </section>
    )
}