import Banner from "../../components/Banner/Banner";
import Clients from "../../components/Clients/Clients";
import Storie from "../../components/Storie/Storie";
import Success from "../../components/Success/Success";
import Switch from "../../components/Switch/Switch";
import TitleSection from "../../components/TitleSection/TitleSection";
import {  useSelector } from "react-redux"

export default function CasosExito(){
    const {translate:{storieSuccess}} = useSelector(({language})=>language)

    return(
        <>
        <Banner/>
        <TitleSection name={storieSuccess.title} />
        <div className="container">
            <Storie/>
            <Success/>
            <Clients/>
        </div>
        <Switch page="ahorro"/>
        </>
    )
}
