import { GET_COUNTRY_CODE } from './Constants'

const initialState = {
   countryCode: null
  };

  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_COUNTRY_CODE:
        return {
          ...state,
          countryCode: action.payload
        }
  
      default:
        return state;
    }
  };