import Banner from "../../components/Banner/Banner";
import About from '../../components/About/About';
import Success from "../../components/Success/Success";
import empresa from "../../images/empresa.png";

export default function Empresa(){

    return(
        <>
        <Banner image={empresa}/>
        <div id="main">
            <About />
            <Success />
        </div>
        </>
    )
}