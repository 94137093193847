import EmoForm from "../../components/EmoForm/EmoForm";
import Banner from "../../components/Banner/Banner";
import Clients from "../../components/Clients/Clients";
import emoImage from "../../images/emo.png";
import {  useSelector } from "react-redux"

import TitleSection from "../../components/TitleSection/TitleSection";

export default function Optimizacion(){
    
    const {translate:{emo}} = useSelector(({language})=>language)

    return(
        <>
        <Banner name={"emo"} image={emoImage} />
        <TitleSection name={emo.title} />
        <EmoForm />
        <Clients/>
        </>
    )
}