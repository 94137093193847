import styled from "styled-components";
import { COLOR_SUCCESS } from "../../styles/global";

export const FloatsContainer = styled.div`
    position: fixed;
    right: .5rem;
    top: 50vh;
    z-index: 3000;
`

export const ButtonContainer = styled.div`
    cursor: pointer;
    img{
        width: 6rem;
    }
    label{
        position: absolute;
        bottom: 0;
        left: 1rem;
        background: ${COLOR_SUCCESS};
        width: 1.5rem;
        height: 1.5rem;
        font-size: .9rem;
        line-height: normal;
        text-align: center;
        border-radius: 9999px;
        color: #fff;
        box-shadow: -1px 1px 6px #000000c4;
    }
    animation-name: fade-in;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
    @keyframes fade-in {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.1);
        }
    }
`