import styled from "styled-components";
import { COLOR_SECONDARY } from "../../styles/global";


export const AccordionContainer = styled.div`
border: 1px solid grey;
border-radius: 6px;
>button{
    padding: .5rem;
    width: 100%;
    background-color:${({buttonColor})=>buttonColor};
    border: none;
    outline: none;
    color: #fff;
    font-weight: 600;
    text-align: initial;
    filter: ${({disabled})=>disabled?"opacity(0.5)":""};
    i{
        margin-right:.5rem;
        transition: transform .8s;
        transform:${({isOpen})=>isOpen?"rotateZ(180deg)":"rotateZ(0deg)"};
    }
}
>div{
    transition: max-height .5s;
    max-height: ${({isOpen})=>isOpen?isOpen+"px":"0"};
    position: relative;
    z-index: 100;
    overflow: hidden;
    
}
`