import styled from "styled-components";
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_SUCCESS, TABLET } from "../../styles/global";

export const InfoModalContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: inherit;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    section{
        background: #fff;
        width: 60%;
        padding: 2rem;
        border-radius: 6px;
        h1{
            font-weight: 100;
            text-transform: uppercase;
            i{
                color: ${({isSuccess})=>isSuccess?COLOR_PRIMARY:COLOR_SUCCESS};
                margin-right:1rem;
            }
        }
        p{
            font-size: 2rem;
            border-bottom: 1px solid ${COLOR_SECONDARY};
            padding:1rem;
        }
    }

    @media only screen and (max-width:${TABLET}){
        section{
            width: 100%;
        }
    }
`