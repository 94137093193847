import { useEffect, useState } from "react"
import styles from "./index.module.scss"
import axios from "axios"
import { countries } from "../../utils/countries"
import { useGetCountryCode } from "../../hooks/useGetCountryCode"
import readXlsxFile from 'read-excel-file'
import { useDispatch, useSelector } from "react-redux"
import { resetEmo, setMotorsListEmo, setPersonalDataEmo, setUseFile } from "../../redux/emo/EmoActions"
import { validateEmail } from "../../utils/validateEmail"

export function infoValidation(input){
    let error= {}
    if(!input.name) error.name = true
    if(!input.email) error.email = true
    if(input.email){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!(re.test(input.email.toLowerCase()))) error.email = true
    }
    if(!input.country) error.country = true
    if(!input.projectName) error.projectName = true
    if(!input.kWCost) error.kWCost = true
    if(input.email && !validateEmail(input.email)) error.email = true
    return error
}

export function motorValidation(input){
    let error= {}
    if(!input.motorName) error.motorName = true
    if(!input.amps) error.amps = true
    if(!input.hoursPerDay) error.hoursPerDay = true
    if(input.hoursPerDay && input.hoursPerDay <1 || input.hoursPerDay >24) error.hoursPerDay = true
    if(!input.daysPerMonth) error.daysPerMonth = true
    if(input.daysPerMonth && input.daysPerMonth <1 || input.daysPerMonth >31) error.daysPerMonth = true
    if(!input.powerFactor) error.powerFactor = true
    if(input.powerFactor && input.powerFactor <1|| input.powerFactor>99) error.powerFactor = true
    if(!input.startMode) error.startMode = true
    if(!input.voltage) error.voltage = true
    if(input.voltage && input.voltage<360 || input.voltage>600 ) error.voltage = true

    return error
}

export default function EmoForm(){

    const dispatch = useDispatch()

    const personalData = useSelector(({emo})=>emo.personal)
    const motorsListData = useSelector(({emo})=>emo.motorsList)
    const {translate:{emo}} = useSelector(({language})=>language)


    const [tamaño, setTamaño]= useState(navigator.userAgent.match(/Android/i)?window.screen.width:window.innerWidth)

    const handleResizes = (e) => {

        if( navigator.userAgent.match(/Android/i)){
            setTamaño(window.screen.width)
        }else{
            setTamaño(window.innerWidth);
        }
    
    };
  
    useEffect(()=>{
      window.addEventListener("resize", handleResizes);
      if(personalData){
          setPersonal(personalData)
      }
      if(motorsListData){
          setMotorsList(motorsListData)
      }
      return function(){
        window.removeEventListener("resize", handleResizes);
      }
    },[])

    const[handle,setHandle] = useState(false)
    const[classe,setClasse] = useState()

    useEffect(()=>{
        setClasse(handle)
    },[handle])

    const[personal, setPersonal] = useState(
        {
            name:"",
            email:"",
            country:"",
            celPhone:"",
            projectName:"",
            kWCost:""
        }
    )

    const {infoCodeCountry} = useGetCountryCode()

    useEffect(()=>{ 
            setPersonal({
                country: infoCodeCountry.country,
                celPhone: infoCodeCountry.celCode,
                ...personalData
            })
    },[infoCodeCountry,personalData])


    const[personalError, setPersonalError] = useState({error:true})
    const[personalModal,setPersonalModal] = useState(false)


    function handlePersonal({target:{value,type,name}}){
        let valor = value
        if(type==="number" && !isNaN(valor)) valor = parseInt(valor)
        setPersonal({...personal,[name]:valor})
    }
    function handlePersonalSubmit(){
        let validation = infoValidation(personal)
        setPersonalError(validation)
        if(Object.keys(validation).length>0) return setPersonalModal(true)
        dispatch(setPersonalDataEmo(personal))
        setHandle("ftos")
        handleTop()
    }

    const[motor, setMotor] = useState(
        {
            motorName:"",
            amps:"",
            hoursPerDay:"",
            hp:"",
            kw:"",
            daysPerMonth:"",
            powerFactor:"",
            startMode:"Directa / (Y-D)",
            voltage:""
        }
    )
    const[motorError, setMotorError] = useState({motorError:true})
    const [motorModal,setMotorModal] = useState(false)
    function handleMotor({target:{value,type,name}}){
        let valor = value
        if(type==="number" && !isNaN(valor)) valor = parseInt(valor)
        setMotorError(motorValidation({...motor,[name]:value}))
        setMotor({...motor,[name]:value})
    }
    function handleMotorSubmit(){
        let validation = motorValidation(motor)
        setMotorError(validation)
        handleTopMotor()
        if(Object.keys(validation).length>0) return setMotorModal(true)
        let motorAdd= motor
        motorAdd.powerFactor = motorAdd.powerFactor
        setMotorsList([...motorsList, motorAdd])
        dispatch(setMotorsListEmo([...motorsList, motorAdd]))
        setMotor(
            {
                motorName:"",
                amps:"",
                hoursPerDay:"",
                hp:"",
                kw:"",
                daysPerMonth:"",
                powerFactor:"",
                startMode:"Directa / (Y-D)",
                voltage:""
            }
        )
    }

    const[motorsList, setMotorsList] = useState([])
    const [motorsModal,setMotorsModal] = useState(false)

    function handleMotorDelete(index) {
        let list = motorsList
        let newList = list.filter((e,i)=>i!==index)
        setMotorsList(newList)
        dispatch(setMotorsListEmo(newList))
        setMotor(
            {
                motorName:"",
                amps:"",
                hoursPerDay:"",
                hp:"",
                kw:"",
                daysPerMonth:"",
                powerFactor:"",
                startMode:"Directa / (Y-D)",
                voltage:""
            }
        )
    }
    function handleMotorEdit(i){
        let list = motorsList
        let aux = list.splice(i,1)
        setMotorsList(list)
        setMotor(
            {
                motorName:aux[0].motorName,
                amps:aux[0].amps,
                hoursPerDay:aux[0].hoursPerDay,
                hp:aux[0].hp,
                kw:aux[0].kw,
                daysPerMonth:aux[0].daysPerMonth,
                powerFactor:aux[0].powerFactor,
                startMode:aux[0].startMode,
                voltage:aux[0].voltage
            }
        )
    }

    function handleMotorsSubmit(){
        if(motorsList.length===0) return setMotorsModal(true)
        handleQuoteSubmit()
    }

    const [quoteModal,setQuoteModal] = useState(false)


    async function handleQuoteSubmit(){       
        await axios.post(process.env.REACT_APP_API+"/project/create",

        {
                    name:personal.projectName,
                    email:personal.email,
                    country:personal.country,
                    type:"EMO",
                    kWCost:personal.kWCost,
                    quotationInformation:{
                        phone: personal.celPhone.toString(),
                        name: personal.name
                    },
                    lines:motorsList          
            }
        )         
        setQuoteModal(true)
    }
    function handleFinish(){
        setQuoteModal(false)
        setPersonal(
            {
                name:"",
                email:"",
                country: infoCodeCountry.country,
                celPhone: infoCodeCountry.celCode,
                projectName:"",
                kWCost:""
            }
        )
        setPersonalError({error:true})
        setMotor(
            {
                motorName:"",
                amps:"",
                hoursPerDay:"",
                hp:"",
                kw:"",
                daysPerMonth:"",
                powerFactor:"",
                startMode:"Directa / (Y-D)",
                voltage:""
            }
        )
        setMotorError({error:true})
        setMotorsList([])
        if(!fileUse)setHandle("stof");
        setBaja(false)
        setFileName("")
        dispatch(resetEmo())
    }

    useEffect(()=>{
         if ( motor.amps && motor.voltage && motor.powerFactor) {
            const kw = (Number(motor.amps) * Number(motor.voltage) * Number(motor.powerFactor/100) * 1.732050808) / 1000
            const hp = kw * 1.341;
            setMotor({...motor, kw: parseInt(kw.toFixed(0)), hp: parseInt(hp.toFixed(0)) })
         }
         else setMotor({...motor,kw:0, hp:0})  
    },[motor.powerFactor, motor.voltage, motor.amps])

    function handleAddPic(e,i){    
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            setMotor({...motor,image:reader.result})
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    function handleTop(){
        let aux = document.getElementById(styles.contact_form);
        var topPos = aux.offsetTop;
        window.scrollTo(0,topPos-(56+72));
    }
    function handleTopMotor(){
        let aux = document.getElementById(styles.contact_form);
        var topPos = aux.offsetTop;
        window.scrollTo(0,topPos-20);
    }

      const schema = {
        'Motor Name':{
            prop: 'motorName',
            type: String,
            required: true
        },
        'Start Mode': {
            prop: 'startMode',
            type: String,
            required: true
        },
        'Daily Use':{
            prop: 'hoursPerDay',
            type: Number,
            required: true
        },
        'Monthly Use':{
            prop: 'daysPerMonth',
            type: Number,
            required: true
        },
        'Voltage':{
            prop: 'voltage',
            type: Number,
            required: true
        },
        'Amperes':{
            prop: 'amps',
            type: Number,
            required: true
        },
        'PowerFactor':{
            prop: 'powerFactor',
            type: Number,
            required: true
        }
      }

    const [ fileModal, setFileModal] = useState(false)
    const [fileErrors,setFileErrors] = useState([])
    const [fileName,setFileName]= useState()
   
    function handleFileChange(input){
        setFileName(input.target.files[0].name)
        readXlsxFile(input.target.files[0],{schema}).then(({rows,errors}) => {
            // `rows` is an array of rows
            // each row being an array of cells.
            if(errors?.length>0){
                setFileErrors(errors)
                setFileModal(true)
                setMotorsList([])
            }else{
                const motors=rows.map(motor => {
                    motor.kw = Number(((Number(motor.amps) * Number(motor.voltage) * Number(motor.powerFactor/100) * 1.732050808) / 1000).toFixed(0))
                    motor.hp = Number((motor.kw * 1.341).toFixed(0));
                    return motor
                });
                setMotorsList([...motorsList, ...motors])
                dispatch(setMotorsListEmo([...motorsList, ...motors]))
            }
        })
    }

    //const [baja, setBaja] = useState(false)
    function setBaja(data){
        dispatch(setUseFile(data))
    }

    const fileUse = useSelector(({emo})=>emo.fileUse)

    const [ motorsListModal, setMotorsListModal] = useState(false)

    async function handleFileSubmit(){
        let validation = infoValidation(personal)
        setPersonalError(validation)
        if(Object.keys(validation).length>0) return setPersonalModal(true)
        if(motorsList.length<1) return setMotorsListModal(true)
        await handleQuoteSubmit()
    }

    return(
        <div id={styles.contact_form} className={`${styles[classe]} `}>
            <div className={styles.personalContainer}>
                <div className={styles.personalForm}>
                <h4><i class="fas fa-info-circle"></i>{emo.personalInfo}</h4>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">{emo.nameLabel}</label>
                    <input type="text" name="name" onChange={handlePersonal} className={personalError.name?"form-control is-invalid":"form-control"} id="name" placeholder="Joe Doe" value={personal.name}/>
                </div>

                <div className="mb-3">
                    <label for="email" className="form-label">Email</label>
                    <input type="email" name="email" onChange={handlePersonal} className={personalError.email?"form-control is-invalid":"form-control"} id="email" value={personal.email}/>
                </div>

                <div className="mb-3">
                    <label for="country" className="form-label">{emo.countryLabel}</label>
                    <select name="country" value={personal.country} id="country" onChange={handlePersonal} className={personalError.country?"form-select is-invalid":"form-select"}>
                        {countries.map((e,i)=><option key={e+i} value={e}>{e}</option>)}
                    </select>
                </div>

                <div className={"mb-3 "+styles.WhatsApp}>
                    <label for="celPhone" className="form-label">WhatsApp ({emo.optionalLabel})</label>
                    <label for="celPhone">+</label>
                    <input type="number" pattern="[0-9]*" name="celPhone" onChange={handlePersonal} className={personalError.celPhone?"form-control is-invalid":"form-control"} id="celPhone" value={personal.celPhone}/>
                </div>
                </div>
                <div className={styles.personalForm}>
                <h4><i class="fas fa-info-circle"></i>{emo.projectInfo}</h4>
                <div className="mb-3">
                    <label for="projectName" className="form-label">{emo.projectName}</label>
                    <input type="text" name="projectName" onChange={handlePersonal} className={personalError.projectName?"form-control is-invalid":"form-control"} id="projectName" placeholder={emo.projectName} value={personal.projectName}/>
                </div>

                <div className={"mb-3 "+styles.dolarKw}>
                    <label for="kWCost" className="form-label">{emo.kwLabel}</label>
                    <label for="kWCost">0.</label>
                    <input type="number" pattern="[0-9]*" name="kWCost" min="0" onChange={handlePersonal} className={personalError.kWCost?"form-control is-invalid":"form-control"} id="kWCost"  value={personal.kWCost}/>
                </div>
                <div className={styles.buttonsContainer}>
                    
                    {
                    <>
                    <button type="button" className="btn btn-primary" onClick={handlePersonalSubmit}>{emo.addMotorsButton} <i className="fas fa-arrow-right"></i></button>
                    <p>
                    {emo.orLabel}
                    </p>
                    <button type="button" className="btn btn-primary" onClick={()=>setBaja(!fileUse)}>{emo.excelButton} <i class="fas fa-file-excel"></i></button>
                    </>
                    }
                    {
                    fileUse&&<>    
                    <div className={styles.fileContainer}>

                    
                    
                    <a  className="btn btn-primary" href="plantilla.xlsx" download target="_blank">{emo.downloadExcel} <i class="fas fa-file-download"></i></a>

                    <input onClick={(e)=>{
                        e.target.value=""
                    }} type="file" className={"form-control"} id="inputGroupFile02" onChange={handleFileChange}/>
                    <label className="btn btn-primary" for="inputGroupFile02">

                        {
                        fileName
                        ?
                        <p>
                        {fileName}
                        </p>
                        :
                        <p>
                            {emo.uploadExcel}
                            <i class="fas fa-file-upload"></i>
                        </p>
                        }
                    </label>
                    </div>
                    {
                    motorsList.length>0
                    &&
                    <button 
                    type="button" 
                    className="btn btn-primary" 
                    onClick={handleFileSubmit}>
                        {emo.quoteButton}
                        <i className="fas fa-calculator"></i>
                    </button>
                    }

                    </>
                    }
                </div>

                
                <div>
                </div>
                </div>
                {
                    motorsList.length>1  &&

                    <div 
                    className={styles.tableContainer}
                    style={{gridColumn: "1 / 3"}}
                    >
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">{emo.tableName}</th>
                                    <th scope="col">{emo.tableStart}</th>
                                    {tamaño>900 &&<><th scope="col">Volts</th>
                                    <th scope="col">Amps</th>
                                    <th scope="col">{emo.tablePowerFactor}</th>
                                    <th scope="col">HP</th>
                                    <th scope="col">KW</th>
                                    <th scope="col">{emo.tableDaily}</th>
                                    <th scope="col">{emo.tableMonthly}</th></>}
                                </tr>
                            </thead>
                            <tbody>
                                {motorsList.length>0 && motorsList.map((e,i)=>{
                                    return(
                                    <tr>
                                        <td>{e.motorName}</td>
                                        <td>{e.startMode}</td>
                                        {tamaño>900 &&<><td>{e.voltage}</td>
                                        <td>{e.amps}</td>
                                        <td>{e.powerFactor}</td>
                                        <td>{e.hp}</td>
                                        <td>{e.kw}</td>
                                        <td>{e.hoursPerDay}</td>
                                        <td>{e.daysPerMonth}</td></>}
                                    </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                }
                <div onClick={()=>setPersonalModal(false)} className={`${styles.modal} ${personalModal&&styles.active} ` }>
                    <div>
                        <label><i class="fas fa-exclamation-circle"></i>ERROR</label>
                        <h3>{emo.errorInputs}</h3>
                    </div>
                </div>
                <div onClick={()=>setMotorsListModal(false)} className={`${styles.modal} ${motorsListModal&&styles.active} ` }>
                    <div>
                        <label><i class="fas fa-exclamation-circle"></i>ERROR</label>
                        <h3>{emo.errorQuantityFile}</h3>
                    </div>
                </div>
                <div onClick={()=>setFileModal(false)} className={`${styles.modal} ${fileModal&&styles.active} ` }>
                    <div>
                        <label><i class="fas fa-exclamation-circle"></i>ERROR</label>

                        <h3>{emo.errorFile}</h3>
                        {fileErrors.map((error)=>(
                            <label>
                                {emo.errorFile_1}{error.row}{emo.errorFile_2}{error.column}{emo.errorFile_3}{error.value}
                            </label>
                        ))}
                    </div>
                </div>
                <div onClick={handleFinish} 
                className={`${styles.modal} ${quoteModal&&styles.active} ` }>
                    <div>
                        <p><i class="fas fa-exclamation-circle"></i>{emo.modalSuccessTitle}</p>
                        <h3>{emo.modalSuccessContent}</h3>
                        <h1>{emo.modalSuccessThanks}</h1>
                    </div>
                </div>
            </div>

            <div  className={styles.motorsContainer}>
                <h4>{emo.formTitle}</h4>
                <div className={"input-group mb-3 "+styles.pictureContainer}>
                    <p>{emo.addMotorPlate}</p>
                    <input type="file" className={"form-control"} id="inputGroupFile01" onChange={handleAddPic}/>
                    <label className="input-group-text" for="inputGroupFile01">
                        {!motor.image
                        ?
                        <i className="fas fa-camera"></i>
                        :
                        <img src={motor.image} />
                        }
                        <p>{emo.motorPlateLabel}</p>
                    </label>
                </div>


                <div className={"row g-2  mb-3 "}>
                    <div className="col me-3 d-flex">
                        <div className="mb-3">
                            <label for="motorName" className="form-label">{emo.motorName}</label>
                            <input type="text" name="motorName" onChange={handleMotor} className={motorError.motorName?"form-control is-invalid":"form-control"} value={motor.motorName} id="motorName" placeholder="Siemens"/>
                        </div>
                    </div>

                    <div className="col d-flex">
                        <div className="mb-3">
                            <label for="startMode" className="form-label">{emo.startMode}</label>
                            <select value={motor.startMode} id="startMode" name="startMode" onChange={handleMotor} class="form-select">
                                <option value="Directa / (Y-D)">{emo.startDirect}</option>
                                <option value="Variador de frecuencia">{emo.startFrequency}</option>
                                <option value="Partidor suave">{emo.startSplitter}</option>
                            </select>
                        </div>
                    </div>
                </div>
                




                <div className={"row g-2  mb-3 "}>
                    <div className="col me-3 d-flex">
                        <div className=" d-flex flex-column justify-content-between" >
                            <label for="hoursPerDay">{emo.dailyUse}</label>
                            <input type="number" pattern="[0-9]*" name="hoursPerDay" onChange={handleMotor}  className={motorError.hoursPerDay?"form-control is-invalid":"form-control"} value={motor.hoursPerDay} id="hoursPerDay" placeholder="8"/>
                        </div>
                    </div>
                    <div className="col d-flex">
                        <div className=" d-flex flex-column justify-content-between" >
                            <label for="daysPerMonth">{emo.monthlyUse}</label>
                            <input type="number" pattern="[0-9]*" name="daysPerMonth" onChange={handleMotor}  className={motorError.daysPerMonth?"form-control is-invalid":"form-control"} value={motor.daysPerMonth} id="daysPerMonth" placeholder="20"/>
                        </div>
                    </div>     
                </div>


                <div className={"row g-2  mb-3 "}>
                    <div className="col me-3 d-flex">
                        <div className=" d-flex flex-column justify-content-between" >
                            <label for="voltage">Volts</label>
                            <input type="number" pattern="[0-9]*" defaultValue="380" min="380" name="voltage" onChange={handleMotor} className={motorError.voltage?"form-control is-invalid":"form-control"} value={motor.voltage} id="voltage" placeholder="380"  />
                        </div>
                    </div>
                    <div className="col d-flex">
                        <div className=" d-flex flex-column justify-content-between" >
                            <label for="amps">Amps</label>
                            <input type="number" pattern="[0-9]*" name="amps" onChange={handleMotor}  className={motorError.amps?"form-control is-invalid":"form-control"} value={motor.amps} id="amps" placeholder="5"/>
                        </div>
                    </div>
                    <div className={"col ms-3 d-flex "+styles.powerFactor} >
                        <div className=" d-flex flex-column justify-content-between">
                            <label for="powerFactor">{emo.powerFactor}</label>
                            <label for="powerFactor">0.</label>
                            <input type="number" pattern="[0-9]*" min="01" max="99"name="powerFactor" onChange={handleMotor}  className={motorError.powerFactor?"form-control is-invalid":"form-control"} value={motor.powerFactor} id="powerFactor" placeholder="1"/>
                        </div>
                    </div>
                </div>
                
                

                {/* <div className={"row g-2  mb-3 "}>
                    <div className="col me-3 d-flex">
                        <div className=" d-flex flex-column justify-content-between" >
                        <label for="hp">HP</label>
                        <input disabled type="number" pattern="[0-9]*" name="hp" onChange={handleMotor}  className={motorError.hp?"form-control is-invalid":"form-control"} id="hp" value={motor.hp} placeholder="12"/>
                        </div>
                    </div>
                    <div className="col d-flex">
                        <div className=" d-flex flex-column justify-content-between" >
                        <label for="kw">KW</label>
                        <input disabled type="number" pattern="[0-9]*" name="kw" onChange={handleMotor}  className={motorError.kw?"form-control is-invalid":"form-control"} id="kw" value={motor.kw} placeholder="10"/>
                        </div>
                    </div>
                </div> */}

                
                <div className="d-flex justify-content-center" >
                    <button type="button" className="btn btn-primary" onClick={handleMotorSubmit}>
                        {emo.addMotor}  
                        <i className="fas fa-angle-double-up"></i>
                    </button>
                </div>
                <div className={styles.tableContainer}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{emo.tableName}</th>
                                <th scope="col">{emo.tableStart}</th>
                                {tamaño>900 &&<><th scope="col">Volts</th>
                                <th scope="col">Amps</th>
                                <th scope="col">{emo.tablePowerFactor}</th>
                                <th scope="col">HP</th>
                                <th scope="col">KW</th>
                                <th scope="col">{emo.tableDaily}</th>
                                <th scope="col">{emo.tableMonthly}</th></>}
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {motorsList.length>0 && motorsList.map((e,i)=>{
                                return(
                                <tr key={e.name+i} >
                                    <td>{e.motorName}</td>
                                    <td>{e.startMode}</td>
                                    {tamaño>900 &&<><td>{e.voltage}</td>
                                    <td>{e.amps}</td>
                                    <td>{e.powerFactor}</td>
                                    <td>{e.hp}</td>
                                    <td>{e.kw}</td>
                                    <td>{e.hoursPerDay}</td>
                                    <td>{e.daysPerMonth}</td></>}
                                    <td onClick={()=>handleMotorDelete(i)}><button>X</button></td>
                                    <td onClick={()=>handleMotorEdit(i)}><button className={styles.buttonEdit}>Edit</button></td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className={styles.buttonsContainer}>
                        <button type="button" className="btn" onClick={()=>{
                            setHandle("stof")
                            handleTop()
                            }}>
                            <i className="fas fa-arrow-left"></i>
                            {emo.backButton}
                        </button>
                        <button type="button" className="btn" onClick={handleMotorsSubmit}>
                            {emo.quoteButton}
                            <i className="fas fa-calculator"></i>
                        </button>
                    </div>
                </div>
                <div onClick={()=>setMotorModal(false)} className={`${styles.modal} ${motorModal&&styles.active} ` }>
                    <div>
                        <label><i class="fas fa-exclamation-circle"></i>ERROR</label>

                        <h3>{emo.errorInputs}</h3>
                    </div>
                </div>
                <div onClick={()=>setMotorsModal(false)} className={`${styles.modal} ${motorsModal&&styles.active} ` }>
                    <div>
                        <label><i class="fas fa-exclamation-circle"></i>ERROR</label>

                        <h3>{emo.errorMotorsFile}</h3>
                    </div>
                </div>
                <div onClick={handleFinish} 
                className={`${styles.modal} ${quoteModal&&styles.active} ` }>
                    <div>
                        <p><i class="fas fa-exclamation-circle"></i>{emo.modalSuccessTitle}</p>
                        <h3>{emo.modalSuccessContent}</h3>
                        <h1>{emo.modalSuccessThanks}</h1>
                    </div>
                </div>
            </div>
            <div className={styles.contactContainer}>
            </div>
        </div>
       
    )
}