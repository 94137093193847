import React, { useEffect, useState } from "react";
import {  useDispatch, useSelector } from "react-redux"
import Banner from "../../components/Banner/Banner";
import Catalogue from "../../components/Catalogue/Catalogue";
import Clients from "../../components/Clients/Clients";
import TitleSection from "../../components/TitleSection/TitleSection";
import Switch from "../../components/Switch/Switch";
import catalogo from "../../images/catalogo_banner.jpeg";
import {ModalGlobal} from "../../components/ModalGlobal/ModalGlobal";
import { ProductInfoModal } from "../../components/ProductInfoModal/ProductInfoModal";
import { GlobalInfoModal } from "../../components/GlobalInfoModal/GlobalInfoModal";
import { closeProductModal, setErrorContactCatalogue, setSuccessSubmit } from "../../redux/catalogue/CatalogueActions";
import { MiniTableProductsInfo } from "../../components/MiniTableProductsInfo/MiniTableProductsInfo";
export default function Catalogo(){
    const dispatch = useDispatch()
    const [search, setSearch] =useState()
    const [productsPerPage, setProductsPerPage] = useState(10)
    const [menu, setMenu] = useState(false)
    const {translate:{catalogue}} = useSelector(({language})=>language)
    const {productDetail, addProductModal,cart, errorContactCatalogue, successSubmitModal} = useSelector(({catalogue})=>catalogue)

    return(
        <>
            <ModalGlobal active={productDetail||addProductModal || errorContactCatalogue || successSubmitModal}>
                {
                    addProductModal 
                    && 
                    <GlobalInfoModal
                        title={catalogue.modalSuccessTitle}
                        content={catalogue.modalAddedProduct}
                        extras={<MiniTableProductsInfo catalogue={catalogue} cart={cart} />}
                        closeAction={()=>dispatch(closeProductModal())}
                    />
                }
                {
                    productDetail 
                    && 
                    <ProductInfoModal product={productDetail} 
                    />
                }
                {
                    errorContactCatalogue
                    &&
                    <GlobalInfoModal
                        title={"Error"}
                        success={false}
                        content={catalogue.errorInputs}
                        extras={""}
                        closeAction={()=>dispatch(setErrorContactCatalogue(null))}
                    />
                }
                {
                    successSubmitModal
                    &&
                    <GlobalInfoModal
                        title={catalogue.modalSuccessTitle}
                        content={catalogue.modalSuccessThanks}
                        extras={""}
                        closeAction={()=>dispatch(setSuccessSubmit(null,null,false))}
                    />
                }
            </ModalGlobal>
            <Banner image={catalogo}/>
            <TitleSection 
                name={catalogue.title} 
                handleOnClick={()=>setMenu(!menu)}
                setSearch={setSearch} 
                setProductsPerPage={setProductsPerPage} 
                productsPerPage={productsPerPage}
                translate={catalogue}
                isCatalogue={true}
            />
            <Catalogue 
            menu={menu}  
            setMenu={setMenu} 
            search={search} 
            productsPerPage={productsPerPage}
            />
            <div className="container">
                <Clients/>
            </div>
            <Switch/>
        </>
    )
}