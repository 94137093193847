import Banner from "../../components/Banner/Banner";
import TitleSection from "../../components/TitleSection/TitleSection";

export default function Generacion(){

    return(
        <>
        <Banner/>
        <TitleSection name="Generacion de Energia" />
        </>
    )
}