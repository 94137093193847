import React from 'react';
import { TableContainer } from "./styles"

export function MiniTableProductsInfo({catalogue,cart}){
    return(
        <TableContainer>
            <thead>
                <tr>
                    <th>{catalogue.quantity}</th>
                    <th>{catalogue.name}</th>
                </tr>
            </thead>
            <tbody>
                
                {
                    cart?.map((e)=>{
                        return(
                            <tr key={e.product.id}>
                                <td scope="row">{e.quantity}</td>
                                <td >{e.product.name}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </TableContainer>
    )
}