import React,{ useEffect, useState } from "react"
import { AccordionContainer } from "./styles"

export function GlobalAccordion({children, buttonLabel, disabled, buttonColor, id}){

    const divId= "accordion"+Math.random().toString(36)
    const[open, setOpen] = useState(false)
    function handleOpenAccordion(){
        const height = document.getElementById(divId).scrollHeight
        if(typeof open !=="boolean") setOpen(false)
        else setOpen(height)
    }
    useEffect(()=>{
        if(disabled) setOpen(false)
    },[disabled])
    useEffect(()=>{
        const height = document.getElementById(divId).scrollHeight
        if(typeof open !=="boolean" && !disabled) setOpen(height)
    },[children])

    return(
        <AccordionContainer 
            isOpen={open} 
            disabled={disabled}
            buttonColor={buttonColor}
            id={id&&id}
        >
            <button disabled={disabled} onClick={handleOpenAccordion} >
                <i className="fas fa-caret-down"/>
                {buttonLabel}
            </button>
            <div id={divId} >
                {children}
            </div>
        </AccordionContainer>
    )
}